import React from "react"
import { SvgIcon } from "@mui/material"

const NavbarDigitalTwinIcon = (props) => {
  const { height, width, viewBox, fill, style, stroke, isSelected, ...other } = props
  return isSelected ? (
    <SvgIcon style={style}>
      <svg width={width} height={height} viewBox={viewBox} fill={"none"}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 15.1278C2 15.5456 2.16379 15.9463 2.45532 16.2417C2.74686 16.5372 3.14228 16.7031 3.55457 16.7031L10.8093 16.7031C11.2215 16.7031 11.617 16.5372 11.9085 16.2417C12.2001 15.9463 12.3638 15.5456 12.3638 15.1278L12.3638 5.67607C12.3638 5.2583 12.2001 4.85755 11.9085 4.56213C11.617 4.26671 11.2215 4.10078 10.8093 4.10078L10.032 4.10078L10.032 3.05058C10.032 2.77207 9.92277 2.5049 9.72842 2.30799C9.53406 2.11108 9.27044 2.00039 8.99558 2.00039L5.36824 2.00039C5.09337 2.00039 4.82977 2.11108 4.63541 2.30799C4.44105 2.5049 4.33186 2.77207 4.33186 3.05058L4.33186 4.10078L3.55457 4.10078C3.14227 4.10078 2.74686 4.26671 2.45532 4.56213C2.16379 4.85755 2 5.2583 2 5.67607L2 15.1278Z" fill="#4D44D8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.83594 15.1278C6.83594 15.5456 6.99973 15.9463 7.29126 16.2417C7.5828 16.5372 7.97822 16.7031 8.39051 16.7031L15.6452 16.7031C16.0575 16.7031 16.453 16.5372 16.7445 16.2417C17.036 15.9463 17.1998 15.5456 17.1998 15.1278L17.1998 5.67607C17.1998 5.2583 17.036 4.85755 16.7445 4.56213C16.4529 4.26671 16.0575 4.10078 15.6452 4.10078L14.8679 4.10078L14.8679 3.05058C14.8679 2.77207 14.7587 2.5049 14.5644 2.30799C14.37 2.11108 14.1064 2.00039 13.8315 2.00039L10.2042 2.00039C9.92931 2.00039 9.66571 2.11108 9.47135 2.30799C9.27699 2.5049 9.1678 2.77207 9.1678 3.05058L9.1678 4.10078L8.39051 4.10078C7.97821 4.10078 7.5828 4.26671 7.29126 4.56213C6.99973 4.85755 6.83594 5.2583 6.83594 5.67607L6.83594 15.1278Z" fill="#BBC5FF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.29224 16.2416C7.0007 15.9461 6.83691 15.5455 6.83691 15.1277L6.83691 5.67591C6.83691 5.25814 7.0007 4.85739 7.29224 4.56197C7.58377 4.26655 7.97918 4.10061 8.39149 4.10061H9.16878V3.05042C9.16878 2.77191 9.27797 2.50474 9.47232 2.30782C9.51238 2.26724 9.55539 2.23031 9.60083 2.19727C9.64627 2.23031 9.68927 2.26724 9.72933 2.30782C9.92369 2.50474 10.0329 2.77191 10.0329 3.05042V4.10061H10.8102C11.2224 4.10061 11.6179 4.26654 11.9095 4.56196C12.201 4.85738 12.3647 5.25814 12.3647 5.67591L12.3647 15.1277C12.3647 15.5455 12.201 15.9461 11.9095 16.2416C11.6179 16.537 11.2224 16.703 10.8102 16.703H8.39149C7.9792 16.703 7.58377 16.537 7.29224 16.2416Z" fill="#6D72F6"/>
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon style={style}>
      <svg width={width} height={height} viewBox={viewBox} fill={"none"}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 15.1278C2 15.5456 2.16379 15.9463 2.45532 16.2417C2.74686 16.5372 3.14228 16.7031 3.55457 16.7031L10.8093 16.7031C11.2215 16.7031 11.617 16.5372 11.9085 16.2417C12.2001 15.9463 12.3638 15.5456 12.3638 15.1278L12.3638 5.67607C12.3638 5.2583 12.2001 4.85755 11.9085 4.56213C11.617 4.26671 11.2215 4.10078 10.8093 4.10078L10.032 4.10078L10.032 3.05058C10.032 2.77207 9.92277 2.5049 9.72842 2.30799C9.53406 2.11108 9.27044 2.00039 8.99558 2.00039L5.36824 2.00039C5.09337 2.00039 4.82977 2.11108 4.63541 2.30799C4.44105 2.5049 4.33186 2.77207 4.33186 3.05058L4.33186 4.10078L3.55457 4.10078C3.14227 4.10078 2.74686 4.26671 2.45532 4.56213C2.16379 4.85755 2 5.2583 2 5.67607L2 15.1278Z" fill="#989898"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.83594 15.1278C6.83594 15.5456 6.99973 15.9463 7.29126 16.2417C7.5828 16.5372 7.97822 16.7031 8.39051 16.7031L15.6452 16.7031C16.0575 16.7031 16.453 16.5372 16.7445 16.2417C17.036 15.9463 17.1998 15.5456 17.1998 15.1278L17.1998 5.67607C17.1998 5.2583 17.036 4.85755 16.7445 4.56213C16.4529 4.26671 16.0575 4.10078 15.6452 4.10078L14.8679 4.10078L14.8679 3.05058C14.8679 2.77207 14.7587 2.5049 14.5644 2.30799C14.37 2.11108 14.1064 2.00039 13.8315 2.00039L10.2042 2.00039C9.92931 2.00039 9.66571 2.11108 9.47135 2.30799C9.27699 2.5049 9.1678 2.77207 9.1678 3.05058L9.1678 4.10078L8.39051 4.10078C7.97821 4.10078 7.5828 4.26671 7.29126 4.56213C6.99973 4.85755 6.83594 5.2583 6.83594 5.67607L6.83594 15.1278Z" fill="#D3D3D3"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.29224 16.2416C7.0007 15.9461 6.83691 15.5455 6.83691 15.1277L6.83691 5.67591C6.83691 5.25814 7.0007 4.85739 7.29224 4.56197C7.58377 4.26655 7.97918 4.10061 8.39149 4.10061H9.16878V3.05042C9.16878 2.77191 9.27797 2.50474 9.47232 2.30782C9.51238 2.26724 9.55539 2.23031 9.60083 2.19727C9.64627 2.23031 9.68927 2.26724 9.72933 2.30782C9.92369 2.50474 10.0329 2.77191 10.0329 3.05042V4.10061H10.8102C11.2224 4.10061 11.6179 4.26654 11.9095 4.56196C12.201 4.85738 12.3647 5.25814 12.3647 5.67591L12.3647 15.1277C12.3647 15.5455 12.201 15.9461 11.9095 16.2416C11.6179 16.537 11.2224 16.703 10.8102 16.703H8.39149C7.9792 16.703 7.58377 16.537 7.29224 16.2416Z" fill="#F2F2F2"/>
      </svg>
    </SvgIcon>
  )
}

NavbarDigitalTwinIcon.defaultProps = {
  width: "19",
  height: "19",
  viewBox: "0 0 19 19",
  fill: "#8D9FB2",
  stroke: "",
  isSelected: false
}

export default NavbarDigitalTwinIcon
