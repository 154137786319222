import React, { useState } from "react"
import { AnalyticsFilterContainer, AnalyticsFilterSectionBox, CalendarBox, FilterSectionClearAllText, FilterSectionTitle, FilterTitleContainer, FilterTitleText, GranularityBox, GranularityContainer } from "../../PagesContent/AlertDrillDown/Components/StyledComponent"
import AntDatePicker from "../../Helper/DatePicker/AntDatePicker"
import AccordionTypeGenericFilter from "../../Components/AccordionTypeGenericFilter"
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";
import CustomIcon from "../../Components/CustomIcon";
import dayjs from "dayjs";
import { rangePresets } from "../../Static/Data/Analytics/Analytics";

function FilterSection({ pagesContent, granularity, setGranularity, onDateChange, onResetFilters, calendarEndDate, calendarStartDate, defaultEndDate, defaultStartDate, analyticsDisabledDate }) {
  const queryParams = decryptTheParams()
  const allGranularity = [
    { label: 'Daily', shortLabel: "D", value: 'daily' },
    { label: 'Weekly', shortLabel: "W", value: 'weekly' },
    { label: 'Monthly', shortLabel: "M", value:'monthly' }
  ]
  const [open, setOpen] = useState(false);
  const [isCalendarHover, setIsCalendarHover] = React.useState(false);

  const handleCalendarOpen = (open) => {
    if (!open) {
      setIsCalendarHover(false);
    }
    setOpen(open)
  }

  const handleDateChange = (startDate, endDate) => {
    setOpen(false)
    setIsCalendarHover(false);
    onDateChange(startDate, endDate)
  }

  const resetFilters = () => {
    onResetFilters()
  }
  return (
    <AnalyticsFilterContainer>
      <FilterTitleContainer>
        <FilterSectionTitle>Filter</FilterSectionTitle>
        <FilterSectionClearAllText onClick={resetFilters}>Clear all</FilterSectionClearAllText>
      </FilterTitleContainer>
      <AnalyticsFilterSectionBox>
        <FilterTitleText>Date Range</FilterTitleText>        
        <CalendarBox className="analytics-drill-down-calendar analytics-calendar analytics-calendar-no-filters">
          <AntDatePicker
            popupClassName="analytics-calendar-popup"
            changeTheDate={handleDateChange}
            date={[calendarStartDate, calendarEndDate]}
            allowClear={true}
            onOpenChange={handleCalendarOpen}
            onMouseEnter={() => setIsCalendarHover(true)}
            onMouseLeave={() => setIsCalendarHover(false)}
            disabledDate={analyticsDisabledDate}
            rangePresets={[
              ...rangePresets, 
              {
                label: "Reset",
                value: [dayjs(defaultStartDate), dayjs(defaultEndDate)]
              } 
            ]}
            suffixIcon={<CustomIcon stroke={open || isCalendarHover ? "#6D72F6" : "#444B51"} name="calendar"/>}
          />
        </CalendarBox>
        <GranularityContainer>
          {allGranularity.map((item) => (
            <GranularityBox
              onClick={() => setGranularity({granularity: item.value})}
              isSelected={granularity.granularity === item.value}
            >
              {item.shortLabel}
            </GranularityBox>
          ))}
        </GranularityContainer>
      </AnalyticsFilterSectionBox>
      <AccordionTypeGenericFilter 
        filtersConfig={pagesContent.filterConfigurations?.[queryParams?.chartName]?.filters}
      />
    </AnalyticsFilterContainer>
  )
}

export default FilterSection
