import { SvgIcon } from "@mui/material"
import React from "react"

const CloseSidebarSvg = (props) => {
  const { height, width, viewBox, fill, style, stroke, isSelected, ...other } = props
  return <SvgIcon style={style}>
    <svg width={width} height={height} viewBox={viewBox} fill='none'>
      <path d="M17.3234 8.82422H7.0293" stroke={fill} stroke-width="1.47059" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.1764 13.9709L7.0293 8.82382L12.1764 3.67676" stroke={fill} stroke-width="1.47059" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1 2L1 16" stroke={fill} stroke-width="1.47059" stroke-linecap="round"/>
    </svg>
  </SvgIcon>
}

CloseSidebarSvg.defaultProps = {
  width: "21",
  height: "18",
  viewBox: "0 0 21 18",
  fill: "#9B9B9B",
  stroke: "",
  isSelected: false,
  style: {}
}

export default CloseSidebarSvg
