import React, { useState } from "react"
import AntDatePicker from "../Helper/DatePicker/AntDatePicker";
import { CalendarBox } from "../PagesContent/ChargingAnalytics/Components/StyledComponent";
import { FlexStartBox } from "./CustomBox";
import CustomIcon from "./CustomIcon";
import GenericFilterContainer from "../Features/Menu/GenericFilterContainer";

export default function FilterWithCalendar({ disabledDate, onDateChange, date, allowClear, page, rangePresets, filtersConfig, onSelectFilter, onResetFilters }) {
  const [open, setOpen] = useState(false);
  const [isCalendarHover, setIsCalendarHover] = React.useState(false);
  const handleCalendarOpen = (open) => {
    if (!open) {
      setIsCalendarHover(false);
    }
    setOpen(open)
  }

  const handleDateChange = (startDate, endDate) => {
    setIsCalendarHover(false)
    setOpen(false)
    onDateChange(startDate, endDate)
  }

  return (
    <FlexStartBox sx={{ height: "50px" }}>
      <GenericFilterContainer
        filterType="analytics"
        showResetButton={false}
        filtersConfig={filtersConfig}
        onSelectFilter={onSelectFilter}
        onResetFilters={onResetFilters}
      />
      <CalendarBox className={"analytics-calendar" + (open ? " analytics-calendar-active" : "") + (filtersConfig?.length ? "" : " analytics-calendar-no-filters")}>
        <AntDatePicker
          popupClassName="analytics-calendar-popup"
          disabledDate={disabledDate}
          changeTheDate={handleDateChange}
          onOpenChange={handleCalendarOpen}
          date={date}
          onMouseEnter={() => setIsCalendarHover(true)}
          onMouseLeave={() => setIsCalendarHover(false)}
          allowClear={allowClear}
          page={page}
          rangePresets={rangePresets}
          suffixIcon={<CustomIcon stroke={open || isCalendarHover ? "#6D72F6" : "#444B51"} name="calendar"/>}
        />
      </CalendarBox>
    </FlexStartBox>
  )
}
