import { SvgIcon } from "@mui/material"
import React from "react"

const NavbarBatteryHealthIcon = (props) => {
  const { height, width, viewBox, fill, style, stroke, isSelected, ...other } = props
  return isSelected 
    ? <SvgIcon
      style={style}
    >
      <svg 
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.51904 5.7224C8.11976 2.54097 4.86017 2.28937 3.33956 3.64473C2.10205 4.72414 1.5116 7.42673 2.63588 9.88585C4.58516 14.1142 9.51904 16.3786 9.51904 16.3786C9.51904 16.3786 14.4529 14.1548 16.4022 9.92643C17.5265 7.49166 16.936 4.79718 15.6985 3.68531C14.1779 2.28937 10.9183 2.54097 9.51904 5.7224Z" fill="#4D44D8" stroke="#4D44D8" stroke-width="0.871212" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.51562 9.59395H6.28479L8.71128 12.0287L11.1378 7.15918L12.7554 9.59395H16.5246" stroke="#BBC5FF" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </SvgIcon>
    : <SvgIcon
      style={style}
    >
      <svg 
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.51904 5.7224C8.11976 2.54097 4.86017 2.28937 3.33956 3.64473C2.10205 4.72414 1.5116 7.42673 2.63588 9.88585C4.58516 14.1142 9.51904 16.3786 9.51904 16.3786C9.51904 16.3786 14.4529 14.1548 16.4022 9.92643C17.5265 7.49166 16.936 4.79718 15.6985 3.68531C14.1779 2.28937 10.9183 2.54097 9.51904 5.7224Z" fill="#989898" stroke="#989898" stroke-width="0.871212" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.51562 9.59395H6.28479L8.71128 12.0287L11.1378 7.15918L12.7554 9.59395H16.5246" stroke="#D3D3D3" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </SvgIcon>
}

NavbarBatteryHealthIcon.defaultProps = {
  width: "19",
  height: "19",
  viewBox: "0 0 19 19",
  fill: "#8D9FB2",
  stroke: "",
  isSelected: false,
}

export default NavbarBatteryHealthIcon
