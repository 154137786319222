import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  NavAccordion,
  NavAccordionDetails,
  NavAccordionListItemBox,
  NavAccordionListItemText,
  NavAccordionSummary,
  NavListItemText,
} from "../../../Components/StyledComponent";
import CustomIcon from "../../../Components/CustomIcon";
import { FlexStartBox } from "../../../Components/CustomBox";
import { Tooltip } from "antd";
import { Box } from "@mui/material";

const DropdownNavLink = ({
  name,
  navigationData,
  isExpanded,
  sidebarOpen,
  setSidebarOpen,
  availablePages,
  onAccordionChange = () => {},
}) => {
  const navigate = useNavigate();
  const [isHover, setIsHover] = useState(false);
  const [isAccordionSelected, setIsAccordionSelected] = useState(false);

  const handleNavigation = (route) => navigate(route);

  const handleAccordionToggle = (panel) => (event, newExpanded) => {
    onAccordionChange(newExpanded ? panel : false);
    setSidebarOpen(true)
  };

  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);

  useEffect(() => {
    let accordionSelected = false
    navigationData?.listItems?.filter((page) => page.isActive).map((page) => {
      const isActivePage = window.location.pathname === page.to

      if (isActivePage) {
        accordionSelected = true
      }
    })

    setIsAccordionSelected(accordionSelected)
  }, [window.location.pathname])

  const ToolTipComponent = sidebarOpen ? Box : Tooltip

  return (
    <NavAccordion expanded={isExpanded} isSelected={isAccordionSelected} onChange={handleAccordionToggle(name)}>
      <NavAccordionSummary
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        isExpanded={isExpanded}
        isHover={isHover}
        showExpandIcon={sidebarOpen}
        isSelected={isAccordionSelected}
      >
        <ToolTipComponent
          placement="right"
          title={navigationData.title}
          zIndex={2000}
        >
          <FlexStartBox>
              <CustomIcon
                name={navigationData.iconName}
                isSelected={isAccordionSelected}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 19,
                  height: "24px"
                }}
              />
            {sidebarOpen && (
              <NavListItemText isSelected={isAccordionSelected}>
                {navigationData.title}
              </NavListItemText>
            )}
          </FlexStartBox>
        </ToolTipComponent>
      </NavAccordionSummary>
      <NavAccordionDetails>
        {navigationData?.listItems
          ?.filter((page) => page.isActive)
          .map((page) => {
            const isActivePage = window.location.pathname === page.to

            if (!availablePages.includes(page.mykey)) {
              return null;
            }
            
            return (
              <NavAccordionListItemBox
                key={page.mykey}
                onClick={() => handleNavigation(page.to)}
              >
                {sidebarOpen && (
                  <NavAccordionListItemText isSelected={isActivePage}>
                    {page.text}
                  </NavAccordionListItemText>
                )}
              </NavAccordionListItemBox>
            );
          })}
      </NavAccordionDetails>
    </NavAccordion>
  );
};

export default DropdownNavLink;
