import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux";
import FleetAlertDistributionChart from "../AlertAnalytics/Charts/FleetAlertDistributionChart";
import { getCurrentMonthStartAndEndDate } from "../../Helper/DatePicker/DateConverter";
import dayjs from 'dayjs';
import { rangePresets } from "../../Static/Data/Analytics/Analytics";
import { ChartSectionContainer } from "./Components/StyledComponent";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";
import FleetAlertStatus from "../AlertAnalytics/Charts/FleetAlertStatus";
import FleetSeverityDistributionChart from "../AlertAnalytics/Charts/FleetSeverityDistributionChart";
import FleetAvgResolutionTimeChart from "../AlertAnalytics/Charts/FleetAvgResolutionTimeChart";
import { Box } from "@mui/material";
import { calculateGranularity } from "../../Helper/Analytics/AnalyticsHelper";

function ChartSection({ granularity, setGranularity, handleChartFilter }) {
  const calendarStartDate = useSelector((state) => state.AlertAnalyticsStartDate.value) || getCurrentMonthStartAndEndDate()[0];
  const calendarEndDate = useSelector((state) => state.AlertAnalyticsEndDate.value) || getCurrentMonthStartAndEndDate()[1];
  const [cardSubtitleTimePeriodText, setCardSubtitleTimePeriodText] = useState("")
  const selectedFilters = useSelector((state) => state.SelectedFilterValues.value)
  const queryParams = decryptTheParams()

  useEffect(() => {
    setGranularity({granularity: calculateGranularity(calendarStartDate, calendarEndDate, granularity.granularity)})
    calculateCardSubtitleTimePeriodText()
  },[calendarStartDate, calendarEndDate])

  const calculateCardSubtitleTimePeriodText = () => {
    const matchedPreset = rangePresets.find((preset) => {
      return preset.value[0].format("YYYY-MM-DD") === calendarStartDate && preset.value[1].format("YYYY-MM-DD") === calendarEndDate
    })

    if (matchedPreset) {
      setCardSubtitleTimePeriodText(`${matchedPreset?.label}`)
    } else {
      setCardSubtitleTimePeriodText(`${dayjs(calendarStartDate).format("D MMM, YYYY")} - ${dayjs(calendarEndDate).format("D MMM, YYYY")}`)
    }
  }

  let ChartComponent = Box

  switch(queryParams?.chartName) {
    case "fleetAlertDistribution":
      ChartComponent = FleetAlertDistributionChart
      break;
    case "fleetAlertStatus":
      ChartComponent = FleetAlertStatus
      break;
    case "fleetAvgResolutionTime":
      ChartComponent = FleetAvgResolutionTimeChart
      break;
    case "fleetSeverityDistribution":
      ChartComponent = FleetSeverityDistributionChart
      break;
  }


  return (
    <ChartSectionContainer>
      <ChartComponent chartSelectedFilter={handleChartFilter} cardSubtitleTimePeriodText={cardSubtitleTimePeriodText} filters={selectedFilters} startDate={calendarStartDate} endDate={calendarEndDate} granularity={granularity}/>
    </ChartSectionContainer>
  )
}

ChartSection.defaultProps = {
  handleChartFilter: () => {}
}

export default ChartSection
