import { SvgIcon } from "@mui/material"
import React from "react"

const ExpandSidebarSvg = (props) => {
  const { height, width, viewBox, fill, style, stroke, isSelected, ...other } = props
  return <SvgIcon style={style}>
    <svg width={width} height={height} viewBox={viewBox} fill='none'>
      <path d="M3.67658 8.82422H13.9707" stroke={fill} stroke-width="1.47059" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.82364 13.9709L13.9707 8.82382L8.82364 3.67676" stroke={fill} stroke-width="1.47059" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20 2L20 16" stroke={fill} stroke-width="1.47059" stroke-linecap="round"/>
    </svg>

  </SvgIcon>
}

ExpandSidebarSvg.defaultProps = {
  width: "21",
  height: "18",
  viewBox: "0 0 21 18",
  fill: "#9B9B9B",
  stroke: "",
  isSelected: false,
  style: {}
}

export default ExpandSidebarSvg
