import { SvgIcon } from "@mui/material"
import React from "react"

const UncheckedBoxSvg = (props) => {
  const { height, width, viewBox, fill, stroke, ...other } = props
  return (
    <SvgIcon style={{ ...props.style }}>
      <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" fill="white" stroke={stroke}/>
      </svg>
    </SvgIcon>
  )
}

UncheckedBoxSvg.defaultProps = {
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "#6D72F6",
  stroke: "#6D72F6"
}

export default UncheckedBoxSvg
