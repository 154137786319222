import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { PageSectionContainer } from "../../PagesContent/AlertAnalytics/Components/StyledComponent"
import { FlexBox } from "../../Components/CustomBox"
import { AnalyticsDrillDownPageTitleText, DrillDownPageContainer } from "../../PagesContent/AlertDrillDown/Components/StyledComponent"
import FilterSection from "../../Features/AnalyticsDrillDown/FilterSection"
import TableSection from "../../PagesContent/ChargerDrillDown/TableSection"
import ChartSection from "../../PagesContent/ChargerDrillDown/ChartSection"
import { updateQueryParams } from "../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { SetFilterValues } from "../../Actions/Filters";
import { ckDisabledDate, geDateRangesForCk } from "../../Helper/DatePicker/DateConverter";
import { SetChargingAnalyticsEndDate, SetChargingAnalyticsStartDate } from "../../Actions/ChargingAnalytics";
import { SetTablePageNumber } from "../../Actions/Table";

function ChargerDrillDownMain({ pagesContent }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [granularity, setGranularity] = useState({granularity: 'daily'})
  const [chartFilterValue, setChartFilterValue] = useState({})
  const [isCardFilterMounted, setIsCardFilterMounted] = useState(false)
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);
  const defaultStartDate = geDateRangesForCk()[0]
  const defaultEndDate = geDateRangesForCk()[1]
  const calendarStartDate = useSelector((state) => state.ChargingAnalyticsStartDate.value) || defaultStartDate
  const calendarEndDate = useSelector((state) => state.ChargingAnalyticsEndDate.value) || defaultEndDate

  const handleChartFilterValue = (filterMounted, value, label) => {
    if (label) {
      setChartFilterValue({[label]: [value]})
    }
    setIsCardFilterMounted(filterMounted)
  }

  const handleDateChange = (startDate, endDate) => {
    dispatch(SetTablePageNumber(1));
    dispatch(SetChargingAnalyticsStartDate(startDate));
    dispatch(SetChargingAnalyticsEndDate(endDate));
    updateQueryParams(navigate, {'startDate': startDate, 'endDate': endDate});
  }

  const handleResetFilters = () => {
    dispatch(SetFilterValues(defaultFilterValues))
    dispatch(SetChargingAnalyticsStartDate(defaultStartDate));
    dispatch(SetChargingAnalyticsEndDate(defaultEndDate));
    setGranularity({granularity: 'daily'})
    updateQueryParams(navigate, {
      filters: ""
    })
  }

  return (
    <FlexBox>
      <DrillDownPageContainer>
        <PageSectionContainer>
          <AnalyticsDrillDownPageTitleText>
            {pagesContent.pageHeader}
          </AnalyticsDrillDownPageTitleText>
        </PageSectionContainer>
        <ChartSection granularity={granularity} setGranularity={setGranularity} handleChartFilter={handleChartFilterValue}/>
        <TableSection pagesContent={pagesContent} chartFilterValue={chartFilterValue} isCardFilterMounted={isCardFilterMounted}/>
      </DrillDownPageContainer>
      <FilterSection 
        analyticsDisabledDate={ckDisabledDate}
        calendarStartDate={calendarStartDate}
        calendarEndDate={calendarEndDate}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate} 
        pagesContent={pagesContent}
        granularity={granularity}
        setGranularity={setGranularity}
        onDateChange={handleDateChange}
        onResetFilters={handleResetFilters} 
      />
    </FlexBox>
  )
}

export default ChargerDrillDownMain
