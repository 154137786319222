import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import Events from "../../../Analytics/Events";
import CustomIcon from "../../../Components/CustomIcon";
import CardWithHeader from "../../../Features/Cards/CardWithHeader";
import { useNotification } from "../../../Hooks/useNotification";
import { getMetricTrendData } from "../../../Api/ChargingAnalytics";
import CODE from "../../../Static/Constants/StatusCodes";
import { findYAxisPartitionSizeForPositiveAxis, getXAxisDateString, getXAxisLabelForWeeklyGranularity, nextDivisibleBy } from "../../../Helper/Charts/ChartHelper";
import AnalyticsStackBarPlusLine from "../../../Charts/Mixed/AnalyticsStackBarPlusLine";
import { toTitleCase } from "../../../Helper/Formatters/TextFormatter";
import { encryptTheParams } from "../../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { formatDateWithoutYear } from "../../../Helper/DatePicker/DateFormatters";
import moment from "moment";

const defaultChartState = {
  series: {
    line: [{x: null, y: 1}, {x: null, y: 1}, {x: null, y: 2}, {x: null, y: 3}, {x: null, y: 4}, {x: null, y: 5}], 
    column:[{x: null, y: 1}, {x: null, y: 1}, {x: null, y: 2}, {x: null, y: 3}, {x: null, y: 4}, {x: null, y: 5}]
  },
  statusCode: CODE.LOADING
}

const numberOfYAxisPartition = 5

const linePlotYAxisSetting = {
  title: {
    text: 'Number of Vehicles',
    style: {
      color: "#87939D",
      fontSize: '12px',
      fontFamily: 'Roboto',
      fontWeight: 400,
    },
  },
  showAlways: true,
  opposite: true,
  axisBorder: {
    show: false,
    color: '#87939D',
    offsetX: -4,
    offsetY: 0
  },
  labels: {
    style: {
        colors: '#87939D',
        fontSize: '10px',
        fontFamily: 'Roboto',
        fontWeight: 500,
    },
  },
  tickAmount: 5,
  max: (max) => findYAxisPartitionSizeForPositiveAxis(max) * numberOfYAxisPartition, 
  min: 0
}

const TotalChargingTimeChart = ({ filters, startDate, endDate, granularity, cardSubtitleTimePeriodText, clickableHeader, chartSelectedFilter }) => {
  const navigate = useNavigate()
  const { openNotification, closeNotification } = useNotification();
  const [chartState, setChartState] = useState(defaultChartState);
  const [maxSlowMode, setMaxSlowMode] = useState(0)
  const [maxFastMode, setMaxFastMode] = useState(0)
  const isFilterMounted = useSelector((state) => state.IsFiltersMounted.value);
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);
  const yAxisPartitionSize = findYAxisPartitionSizeForPositiveAxis(maxSlowMode + maxFastMode)
  const barPlotYAxisSetting = {
    title: {
      text: 'Hours',
      style: {
        color: "#87939D",
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
      },
    },
    showAlways: true,
    axisBorder: {
      show: false,
      color: '#87939D',
      offsetX: 2.5,
      offsetY: 0
    },
    labels: {
      style: {
          colors: '#87939D',
          fontSize: '10px',
          fontFamily: 'Roboto',
          fontWeight: 500,
      },
    },
    tickAmount: 5,
    max: nextDivisibleBy(maxSlowMode + maxFastMode, yAxisPartitionSize * numberOfYAxisPartition) || 5,
    min: 0
  }
  const [csvData, setCsvData] = useState({
    data: [],
    responseStatus: { code: null }
  })

  useEffect(() => {
    if (isFilterMounted) {
      fetchData();
      chartSelectedFilter(true, null, null)
    }
  }, [
    granularity,
    filters,
    isFilterMounted
  ])

  const fetchData = async () => {
    setChartState(defaultChartState);
    const res = await getMetricTrendData(["vehiclesCount", "slowMode", "fastMode"], startDate, endDate, granularity.granularity, filters, defaultFilterValues)
    const { responseStatus, response } = res;
    
    if (responseStatus.code !== CODE.SUCCESS) {
      setChartState({
        ...defaultChartState,
        statusCode: CODE.NODATA
      });
      return
    }

    generateChartData(response, responseStatus);
  }

  const generateChartData = (response, responseStatus) => {
    const dataLength = response.data?.length;
    let maximumFastMode = 0;
    let maximumSlowMode = 0;
    const series =  {
      column: [
        {
            name: "Fast Mode (Hours)",
            type: 'bar',
            stacked: true,
            color: "#357AB6",
            data: response.data?.map(data => {
                const value = data.metricsArray.find(item => item.type == 'fastMode')?.value
                maximumFastMode = Math.max(maximumFastMode, value)
                
                let x, dateRangeString
                if (granularity.granularity === 'daily') {
                  const date = new Date(data.range.startDate);
                  x = formatDateWithoutYear(date)
                  dateRangeString = moment(data.range.startDate).format('DD MMM, YYYY')
                } else {
                  const result = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity, true)
                  x = result.x;
                  dateRangeString = result.dateRangeString;
                }

                return {
                    x,
                    y: value,
                    dateRangeString
                }
            })
        },
        {
            name: "Slow Mode (Hours)",
            type: 'bar',
            stacked: true,
            color: "#8FE1D9",
            data: response.data?.map(data => {
                const value = data.metricsArray.find(item => item.type == 'slowMode')?.value
                maximumSlowMode = Math.max(maximumSlowMode, value)
                
                let x, dateRangeString
                if (granularity.granularity === 'daily') {
                  const date = new Date(data.range.startDate);
                  x = formatDateWithoutYear(date)
                  dateRangeString = moment(data.range.startDate).format('DD MMM, YYYY')
                } else {
                  const result = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity, true)
                  x = result.x;
                  dateRangeString = result.dateRangeString;
                }

                return {
                    x,
                    y: value,
                    dateRangeString
                }
            })
        }
      ],
      line: {
        name: "Number of Vehicles",
        type: 'line',
        stacked: false,
        color: "#7957FF",
        data: response.data?.map(data => {
          let x, dateRangeString
          if (granularity.granularity === 'daily') {
            const date = new Date(data.range.startDate);
            x = formatDateWithoutYear(date)
            dateRangeString = moment(data.range.startDate).format('DD MMM, YYYY')
          } else {
            const result = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity, true)
            x = result.x;
            dateRangeString = result.dateRangeString;
          }
          return {
            x,
            y: data.metricsArray.find(item => item.type == 'vehiclesCount')?.value,
            dateRangeString
          }
        })
      },
    }

    setMaxSlowMode(Math.round(maximumSlowMode * 100) / 100)
    setMaxFastMode(Math.round(maximumFastMode * 100) / 100)

    setChartState({
      series: series, 
      statusCode: responseStatus.code
    })
  }

  const generateCsvData = () => {
    const formatItem = (fastModeRow, slowModeData, numberOfvehicleData) => ({
      "Date Range": fastModeRow.dateRangeString?.replace(/,/g, " "),
      "Fast Mode Charging Time (Hours)": fastModeRow.y,
      "Slow Mode Charging Time (Hours)": slowModeData.y,
      "Number of Vehicles": numberOfvehicleData.y
    });

    if (chartState.statusCode !== CODE.SUCCESS) {
      return
    }
    const data = [
      ...chartState.series.column[0].data.map((fastModeRow, index) => formatItem(
        fastModeRow, 
        chartState.series.column[1].data[index],
        chartState.series.line.data[index]
      )),
    ];

    setCsvData({
      data,
      responseStatus: { code: CODE.SUCCESS }
    });
  }

  const onClickHeader = () => {
    const temp = {
      chartName: 'chargingTime',
      startDate,
      endDate 
    };
    encryptTheParams(temp, navigate, false, '/ChargerDrillDown', true);
  }
  
  return (
    <CardWithHeader
      id='totalChargingTimeChart'
      title="Total Fleet Charging Time"
      headerClass="header-hover"
      subtitle={`${cardSubtitleTimePeriodText}`}
      iconElement={<CustomIcon name="barGraph" style={{ width: 21, height: 21 }}/>}
      pageLabel="Charging Analytics"
      showCSVDownload
      csvData={csvData}
      csvName={"TotalChargingTime"}
      generateCsvData={generateCsvData}
      onMouseEnter={() =>
        Events("Charging Analytics: Hover over Total Charging Time chart")
      }
      openNotification={openNotification}
      closeNotification={closeNotification}
      cardStyle={{ height: "464px" }}
      bodyStyle={{ height:"363px", position: "relative", paddingLeft: 0, paddingRight: 0 }}
      clickableHeader={clickableHeader}
      onClickHeader={onClickHeader}
    >
      <AnalyticsStackBarPlusLine
        state={chartState.statusCode}
        granularity={toTitleCase(granularity.granularity)}
        stackBarPlotDataSeriesList={chartState.series.column}
        linePlotDataSeries={chartState.series.line}
        barPlotYAxisSetting={barPlotYAxisSetting}
        linePlotYAxisSetting={linePlotYAxisSetting}
      />
    </CardWithHeader>
  )
}

TotalChargingTimeChart.defaultProps = {
  clickableHeader: false,
  chartSelectedFilter: () => {}
}

export default TotalChargingTimeChart;
