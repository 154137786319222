import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Cookies from "universal-cookie";

const cookies = new Cookies();
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(cookies.get("timeZone"));

export const rangePresets = [
  {
    label: "Last 7 Days",
    value: [dayjs().tz().add(-7, "d"), dayjs().tz().add(-1, "d")]
  },
  {
    label: "Last 14 Days",
    value: [dayjs().tz().add(-14, "d"), dayjs().tz().add(-1, "d")]
  },
  {
    label: "Last 1 Month",
    value: [dayjs().tz().add(-1, "d").subtract(1, "month"), dayjs().tz().add(-1, "d")]
  },
  {
    label: "Last 3 Months",
    value: [dayjs().tz().add(-1, "d").subtract(3, "month"), dayjs().tz().add(-1, "d")]
  },
  {
    label: "Last 6 Months",
    value: [dayjs().tz().add(-1, "d").subtract(6, "month"), dayjs().tz().add(-1, "d")]
  }
]

export const alertAnalyticsChartMeta = {
  fleetAlertDistribution: {
    label: "Fleet Alert Distribution",
  },
  fleetAlertStatus: {
    label: "Fleet Alert Status",
  },
  fleetAvgResolutionTime: {
    label: "Fleet Avg. Resolution Time for each alert",
  },
  fleetSeverityDistribution: {
    label: "Fleet Severity Distribution for each Alert",
  }
}

export const chargingAnalyticsChartMeta = {
  energyConsumed: {
    label: "Vehicle Energy Consumed while Charging",
  },
  energyEfficiency: {
    label: "Vehicle Energy Efficiency",
  },
  chargingTime: {
    label: "Vehicle Charging Time",
  },
}
