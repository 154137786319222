import { styled } from "@mui/material/styles";
import { Box, Button, FormControlLabel, MenuItem, Typography } from "@mui/material";
import { ThemeProperties } from "../Theme/ThemeProperties";

const PageContainer = styled(Box)({
  backgroundColor: "#FAFAFA"
})

const OuterBox = styled(Box)({
  flexGrow: 1,
  // backgroundColor: `${ThemeProperties.backgroundPurpleDarker}`,
  paddingTop: "70px",
  paddingLeft: "5.5rem",
  paddingBottom: "100px",
  minWidth: "865px",
  height: "100%",
});

const InnerBox = styled(Box)({
  // backgroundColor: `${ThemeProperties.backgroundPurpleDarker}`,
  minWidth: "800px",
  paddingLeft: "2rem",
  paddingRight: "2rem",
});

// Adjusted styles for DigitalTwinInnerBox to ensure proper padding and styling.
const DigitalTwinInnerBox = styled(InnerBox)({
  paddingLeft: "0",
  fontFamily: "Roboto",
});

const SimpleBox = styled("div")({});

const FullHeightBox = styled(Box)({
  height: "100%",
})

const FullWidthBox = styled(Box)({
  width: "100%",
})

const FlexBox = styled(Box)({
  display: 'flex',
});

const FlexCenterBox = styled(FlexBox)({
  justifyContent: "center",
});

const SubTitleBox = styled(Box)({
  paddingLeft: "0.2rem",
});

const FlexSpaceBetweenBox = styled(FlexBox)({
  justifyContent: "space-between",
});

const FlexStartBox = styled(FlexBox)({
  justifyContent: "flex-start",
  alignItems: "center",
});

const FilterContainer = styled(Box)(({ filterType }) => {
  if (filterType === 'accordionMultiSelect') {
    return {}
  }

  return {
    display: "flex",
    alignItems: "center"
  }
});

const FilterAndSearchContainer = styled(Box)({
  display: 'flex',
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap"
});

const PaginationContainer = styled(FlexBox)({
  width: "100%",
  justifyContent: "right"
})

const ResetFilterBox = styled(Button)({
  fontSize: "12px",
  height: "30px",
  marginTop: 0,
  marginRight: "10px"
});

const BatteryHealthLoaderBox = styled(Box)({
  height: "100vh",
  backgroundColor: ThemeProperties.backgroundPurpleDarker,
});

const BatteryHealthTabContentLoaderBox = styled(Box)({
  height: "90vh",
  backgroundColor: ThemeProperties.white,
});

const DownloadCSVBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  borderRadius: "12px",
  width: "150px",
  // marginTop: "8px",
  height: "28px",
  backgroundColor: "#fff",
  cursor: "pointer",
});

const DownloadCSVPointer = styled(Box)({
  display: "flex",
  alignItems: "center",
  border: "1px solid rgb(189,189,189)",
  borderRadius: "4px",
  width: "120px",
  mr: 1,
  mt: 1,
  mb: 1,
  height: "35px",
  bgcolor: "#fff",
  cursor: "pointer",
});

const DownloadCSVNoPointer = styled(Box)({
  display: "flex",
  alignItems: "center",
  border: "1px solid rgb(189,189,189)",
  borderRadius: "4px",
  width: "120px",
  mr: 1,
  mt: 1,
  mb: 1,
  height: "35px",
  bgcolor: "#fff",
});

const BoxWithOverflow = styled(Box)({
  height: "400px",
  maxHeight: "440px",
  overflowY: "auto",
  overflowX: "hidden",
});

const KPIBox = styled(Box)({
  backgroundColor: ThemeProperties.white,
  borderRadius: ThemeProperties.borderRadius,
  border: "1px solid #ececec",
  padding: "1rem",
  // width: "100%",
  // marginRight: "2rem",
  cursor: "pointer",
  "&:hover": {
    border: `1px solid ${ThemeProperties.purple}`,
  },
  boxShadow: "rgba(0, 0, 0, 0.07) 2.0px 2.0px 0px",
});

const KPIBoxSmallWidth = styled(Box)({
  backgroundColor: ThemeProperties.white,
  borderRadius: ThemeProperties.borderRadius,
  border: "1px solid #ececec",
  padding: "1rem",
  width: "16rem",
  cursor: "pointer",
  "&:hover": {
    border: `1px solid ${ThemeProperties.purple}`,
  },
  boxShadow: "rgba(0, 0, 0, 0.07) 2.0px 2.0px 0px",
});

const KPIBoxCustomWidth = styled(Box)({
  backgroundColor: ThemeProperties.white,
  borderRadius: "4px",
  border: "1px solid #ececec",
  padding: "5px 17px",
  width: "max-content",
  cursor: "pointer",
  "&:hover": {
    border: `1px solid ${ThemeProperties.purple}`,
  },
});

const KPIBoxSmallWidthWithoutHover = styled(Box)({
  backgroundColor: ThemeProperties.white,
  borderRadius: ThemeProperties.borderRadius,
  border: "1px solid #ececec",
  padding: "1rem",
  width: "16rem",
  cursor: "default",
  "&:hover": {
    border: "1px solid #ececec",
  },
});

const LiveMetricsBox = styled(Box)(({ width, color }) => ({
  display: "flex",
  flexDirection: "column",
  width: width || "149px",
  marginTop: "6px",
  justifyContent: "center",
  padding: "5px 0px",
  border: "1px solid",
  borderRadius: "4px",
  minHeight: "50px",
  marginLeft: "20px",
  borderColor: color,

  "@media (max-width: 1024px)": {
    marginTop: "10px",
    marginRight: "10px",
  },
}));

const DigitalTwinChartLoader = styled(Box)(() => ({
  paddingTop: "100px",
  height: "400px",
  width: "100%",
  textAlign: "center",
  "@media (max-width: 800px)": {
    left: "70vw",
  },
  "@media (min-width: 1500px)": {
    left: "55vw",
  },
}));

const ChartFilterBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",

  "@media (max-width: 1024px)": {
    marginTop: "10px",
  },
});

const ChartFilter = styled(FormControlLabel)({
  marginLeft: "5px",

  "@media (max-width: 1024px)": {
    marginBottom: "10px",
  },
});

const KPIBoxWithoutBorder = styled(Box)({
  backgroundColor: ThemeProperties.white,
  borderRadius: ThemeProperties.borderRadius,
  border: `1px solid ${ThemeProperties.purple}`,
  padding: "1rem",
  width: "18rem",
  marginRight: "2rem",
  "&:hover": {
    border: `1px solid ${ThemeProperties.purple}`,
  },
});

const CustomSelect = styled(Box)((props) => ({
  display: props.isComparisonModeOn ? "none" : "block",
  paddingLeft: "49px",
  "@media (max-width: 1024px)": {
    marginTop: "12px",
  },
}));

const Separator = styled(Box)({
  marginLeft: "10px",
  marginRight: "10px",
  width: "1px",
  height: "12px",
  background: "#E9EFF5",
});

const FullWidthSeparator = styled('hr')((props) => ({
  borderTopWidth: props.width || "1px",
  borderTopStyle: props.style || "solid",
  borderTopColor: props.color || "#ececec",
  marginLeft: props.marginLeft || 0,
  marginRight: props.marginRight || 0,
}))

const CustomTabSection = styled(Box)({
  borderBottom: "solid 1px #CDDEEF",
  display: "flex",
  background: '#ffffff',
  justifyContent: "space-between",
  pl: "12px",
  position: "fixed",
  paddingLeft: "11px",
  top: "124px",
  width: "100%",
  zIndex: "2",

  "@media (max-width: 1008px)": {
    top: "154px",
  },
})
const CustomTabSectionOutline = styled(Box)({
  width: "100%",
  position: "fixed",
  paddingTop: "4px",
  paddingLeft: "12px",
  top: "50px",
  zIndex: "2",
  background: "white",
  paddingBottom: "25px",

  "@media (max-width: 1008px)": {
    paddingBottom: "80px"
  },
})

const CustomTabOutline = styled(Box)({
  width: "100%",
  marginTop: "92px",

  "@media (max-width: 1008px)": {
    marginTop: "117px",
  },
})

const CustomHeaderContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flexWrap: "wrap",
  height: "69px",
  "@media (max-width: 1008px)": {
    height: "25px",
  },
})

const ArrowContainer = styled(Box)(({ disabled, left, right, style }) => ({
  opacity: disabled ? 0.3 : 1,
  cursor: disabled ? "default" : "pointer",
  fontSize: 0,
  lineHeight: 0,
  position: "absolute",
  top: "37%",
  display: "block",
  width: "20px",
  padding: 0,
  transform: "translate(0, -50%)",
  color: "transparent",
  border: "none",
  outline: "none",
  background: "transparent",
  left: left,
  right: right,
  ...style
}));

const CustomText = styled(Typography)({
  fontFamily: "Roboto",
  lineHeight: "24px",
})

const ChartHeadingText = styled(CustomText)(({ isClickable }) => ({
  fontSize: "16px",
  fontWeight: "500",
  color: "#1C1C1C",
  "&:hover": {
    color: isClickable ? "#6D72F6" : "#1C1C1C",
  }
}))

const ChartSubTitleText = styled(CustomText)({
  fontSize: "12px",
  fontWeight: "400",
  color: "#444B51"
})

const PageHeadingText = styled(CustomText)({
  fontSize: "24px",
  fontWeight: "500",
})

const ConnectedBatteriesLabel = styled(Typography)({
  fontFamily: "Roboto",
  fontSize: "13px",
  fontWeight: "400",
  color: "#444B51",
})

const ConnectedBatteriesCount = styled(Typography)({
  fontFamily: "Roboto",
  fontSize: "13px",
  fontWeight: "700",
  color: "#1C1C1C"
})

const CardMenuPopoverContainer = styled(Box)({
  display: "flex",
  flexDirection: "column"
})

const CardHeaderBox = styled(FlexSpaceBetweenBox)({
  alignItems: "flex-start",
})

const CardHeaderIconBox = styled(Box)({
  marginTop: "3px",
  marginRight: "5px"
})

const CardBodyContainer = styled(Box)({
  padding: "12px"
})
const CardFooterContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  paddingBottom: "24px"
})

const CardHeaderContainer = styled(Box)(({ isClickable }) => ({
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #E7E5E5",
  padding: "16px 18px 8px 18px",
  maxHeight: "65px",
  borderRadius: "18px 18px 0px 0px",
  "&:hover": {
    backgroundColor: isClickable ? "#F3F5F6" : "transparent",
  },
  cursor: isClickable ? "pointer" : "default",
}));

const FilterLoaderBox = styled(Box)({
  marginLeft: "15px"
})

const FilterArrowBox = styled(Box)({
  marginTop: "3px",
  cursor: "pointer"
})

const AnalyticsCardFilterSelectedLabel = styled(Typography)({
  fontFamily: 'Roboto',
  color: "#6D72F6",
  fontWeight: 400,
  fontSize: "12px",
})

const AnalyticsCardFilterMenuItem = styled(MenuItem)(({ selected }) => ({
  fontFamily: 'Roboto',
  color: selected ? "#6D72F6" : "#444B51",
  fontWeight: 400,
  fontSize: "12px",
  backgroundColor: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F4F4F4",
  },
  "&.Mui-selected": {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#F4F4F4",
    },
  }
}))

const TableTitleText = styled(CustomText)({
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "16px",
  color: "#1C1C1C"
})

const TableTitleTimePeriodText = styled(TableTitleText)({
  color: "#828A92",
  marginLeft: "3px"
})

const TableRecordsCountText = styled(TableTitleText)({
  color: "#828A92",
  marginLeft: "3px"
})

const TableTitleContainer = styled(FlexSpaceBetweenBox)({
  padding: "15px 24px",
  backgroundColor: "#FFF",
  borderRadius: "14px 14px 0 0",
  border: "1px solid #E6E6E6",
  borderBottom: 0
})

export {
  PageContainer,
  OuterBox,
  InnerBox,
  DigitalTwinInnerBox,
  SimpleBox,
  FlexBox,
  FullHeightBox,
  FullWidthBox,
  CustomText,
  FlexStartBox,
  FlexCenterBox,
  ChartHeadingText,
  ChartSubTitleText,
  PageHeadingText,
  CardHeaderIconBox,
  PaginationContainer,
  CardHeaderContainer,
  CardHeaderBox,
  FilterLoaderBox,
  CardMenuPopoverContainer,
  ConnectedBatteriesLabel,
  ConnectedBatteriesCount,
  FlexSpaceBetweenBox,
  BoxWithOverflow,
  DownloadCSVBox,
  DownloadCSVPointer,
  DownloadCSVNoPointer,
  KPIBox,
  CardBodyContainer,
  CardFooterContainer,
  KPIBoxWithoutBorder,
  KPIBoxSmallWidth,
  KPIBoxCustomWidth,
  AnalyticsCardFilterMenuItem,
  AnalyticsCardFilterSelectedLabel,
  KPIBoxSmallWidthWithoutHover,
  LiveMetricsBox,
  DigitalTwinChartLoader,
  ChartFilterBox,
  ChartFilter,
  CustomSelect,
  SubTitleBox,
  Separator,
  FullWidthSeparator,
  CustomTabSection,
  CustomTabOutline,
  CustomTabSectionOutline,
  CustomHeaderContainer,
  TableRecordsCountText,
  ArrowContainer,
  BatteryHealthLoaderBox,
  ResetFilterBox,
  FilterContainer,
  FilterArrowBox,
  FilterAndSearchContainer,
  BatteryHealthTabContentLoaderBox,
  TableTitleText,
  TableTitleContainer,
  TableTitleTimePeriodText
};
