import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import Events from "../../../Analytics/Events";
import CustomIcon from "../../../Components/CustomIcon";
import CardWithHeader from "../../../Features/Cards/CardWithHeader";
import { useNotification } from "../../../Hooks/useNotification";
import { getMetricTrendData } from "../../../Api/ChargingAnalytics";
import CODE from "../../../Static/Constants/StatusCodes";
import { getXAxisDateString, nextDivisibleBy } from "../../../Helper/Charts/ChartHelper";
import AnalyticsBasicTreeMap from "../../../Charts/Treemap/AnalyticsBasicTreeMap";
import { toTitleCase } from "../../../Helper/Formatters/TextFormatter";

const defaultChartState = {
  series: [],
  colorRanges: [
    {
      from: 0,
      to: 0,
      color: '#FFF',
      name: '',
    },
    {
      from: 1,
      to: 5,
      color: '#D2C6FF',
      name: 'low',
    },
    {
      from: 6,
      to: 10,
      color: '#A58EFF',
      name: 'medium',
    },
    {
      from: 10,
      to: 15,
      color: '#6A59AF',
      name: 'high',
    },
    {
      from: 15,
      to: 20,
      color: '#443875',
      name: 'very high',
    },
  ],
  statusCode: CODE.LOADING
}

const yAxisSetting = {
  title: {
    text: 'Number of Charging Sessions',
    style: {
      color: "#87939D",
      fontSize: '12px',
      fontFamily: 'Roboto',
      fontWeight: 400,
    },
    offsetX: 7,
  },
  showAlways: true,
  axisBorder: {
    show: false,
    color: '#87939D',
    offsetX: 0,
    offsetY: 0
  },
  labels: {
    style: {
        colors: '#87939D',
        fontSize: '10px',
        fontFamily: 'Roboto',
        fontWeight: 500,
    },
  },
  tickAmount: 5,
}

const defaultChartLegendSettings = {
  min: '0 Vehicle',
  max: '0 Vehicle',
  gradientStyle: "linear-gradient(90deg, #C5B6FF 0%, #8C6FFF 32.5%, #6147C8 66.67%, #37257D 100%)"
}

const ChargingFrequencyChart = ({ filters, startDate, endDate, granularity, cardSubtitleTimePeriodText }) => {
  const { openNotification, closeNotification } = useNotification();
  const [chartState, setChartState] = useState(defaultChartState);
  const [chartLegendSettings, setChartLegendSettings] = useState(defaultChartLegendSettings);
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);
  const [csvData, setCsvData] = useState({
    data: [],
    responseStatus: { code: null }
  })

  useEffect(() => {
    if (Object.keys(filters).length) {
      fetchData();
    }
  }, [
    granularity,
    filters
  ])

  const fetchData = async () => {
    setChartState(defaultChartState);
    const res = await getMetricTrendData(["chargingFrequency"], startDate, endDate, granularity.granularity, filters, defaultFilterValues)
    const { responseStatus, response } = res;
    
    if (responseStatus.code !== CODE.SUCCESS) {
      setChartState({
        ...defaultChartState,
        statusCode: CODE.NODATA
      });
      return
    }

    generateChartData(response, responseStatus);
  }

  // Distribute the Vehicle Count according to color ranges 
  const getColorRanges = (maxNumberOfBatteries=0) => {
    const numberOfTreeMapColorCodes = 4
    const partitionSize = Math.ceil(maxNumberOfBatteries/numberOfTreeMapColorCodes)
    const colorMap = ['#FFFFFF', "#D2C6FF", "#A58EFF", "#6A59AF", "#443875"]
    let colorRanges = [
      {
        from: 0,
        to: 0,
        color: colorMap[0],
        name: '',
      }
    ]

    let startRange = 1
    while (startRange <= numberOfTreeMapColorCodes * partitionSize) {
      colorRanges.push({
        from: startRange,
        to: startRange + partitionSize - 1,
        color: colorMap[Math.ceil(startRange / partitionSize)],
      })
      startRange = startRange + partitionSize
    }
    setChartLegendSettings((prev) => {
      return {
        ...prev,
        max: `${numberOfTreeMapColorCodes * partitionSize} Vehicles`,
      }
    })
    return colorRanges
  }

  const findYAxisRange = (data) => {
    let maxCount = 0
    data?.forEach(item => {
      const chargingFrequencyData = item.metricsArray?.find(item => item.type == 'chargingFrequency')?.values
      chargingFrequencyData?.forEach(sessionsData => {
        maxCount = Math.max(sessionsData.value, maxCount)
      })
    })
    
    return Math.max(maxCount, 4)
  }

  const generateChartData = (response, responseStatus) => {
    const dataLength = response.data?.length;
    const maxYAxis = findYAxisRange(response.data)
    const numberOfYAxisPartition = 5
    const partitionSize = maxYAxis <= 5 ? 1 : nextDivisibleBy(Math.ceil(maxYAxis/5), 5);
    
    let series = []
    let maxNumberOfBatteries = 0
    for (let i = 1; i <= numberOfYAxisPartition; i++) {
      const seriesRangeMin = (i - 1) * partitionSize + 1
      const seriesRangeMax = i * partitionSize
      const seriesName = maxYAxis <= 5 ? `${seriesRangeMin}` : `${seriesRangeMin} - ${seriesRangeMax}`;
      const seriesData =  {
        name: seriesName,
        data: response.data?.map(data => {
            const batteriesCounts = data.metricsArray.find(item => item.type == 'chargingFrequency')?.values?.filter(batteries => batteries.value >= seriesRangeMin && batteries.value <= seriesRangeMax)?.length
            const {x, dateRangeString } = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity, true)
            maxNumberOfBatteries = Math.max(maxNumberOfBatteries, batteriesCounts)
            return {
                x,
                y: batteriesCounts,
                dateRangeString
            }
        })
      }

      series.push(seriesData)
    }

    const colorRanges = getColorRanges(maxNumberOfBatteries)
    setChartState({
      series: series, 
      statusCode: responseStatus.code,
      colorRanges: colorRanges
    })
  }

  const generateCsvData = () => {
    if (chartState.statusCode !== CODE.SUCCESS) {
      return
    }

    const dateStringsList = chartState.series[0]?.data?.map(item => item.dateRangeString?.replace(/,/g, " "))
    const data = dateStringsList.map(item => {
      let rowData = {
        "Date Range": item,
      }
      chartState.series.map(series => {
        rowData = {
          ...rowData,
          ["Number of Vehicles having " + series.name +  " Charging Session"]: series.data.find(seriesData => seriesData.dateRangeString === item)?.y,
        }
      })

      return rowData
    })

    setCsvData({
      data,
      responseStatus: { code: CODE.SUCCESS }
    });
  }
  
  return (
    <CardWithHeader
      id='totalChargingSessionsChart'
      title="Total Charging Sessions"
      subtitle={`${cardSubtitleTimePeriodText}`}
      iconElement={<CustomIcon name="barGraph" style={{ width: 21, height: 21 }}/>}
      pageLabel="Charging Analytics"
      showCSVDownload
      csvData={csvData}
      csvName={"chargingSessions"}
      generateCsvData={generateCsvData}
      onMouseEnter={() =>
        Events("Charging Analytics: Hover over Total Charging Sessions chart")
      }
      openNotification={openNotification}
      closeNotification={closeNotification}
      cardStyle={{ height: "464px" }}
      bodyStyle={{ height:"380px", position: "relative", paddingLeft: "17px", paddingRight: chartState.statusCode === CODE.SUCCESS ? "22px" : "35px", paddingTop: "5px" }}
    >
      <AnalyticsBasicTreeMap
        state={chartState.statusCode}
        series={chartState.series}
        granularity={toTitleCase(granularity.granularity)}
        rangesSetting={chartState.colorRanges}
        chartLegendSettings={chartLegendSettings}
        yAxisSetting={yAxisSetting}
        tooltipYAxisName={"Vehicles Count:"}
      />
    </CardWithHeader>
  )
}

export default ChargingFrequencyChart;
