import { SvgIcon } from "@mui/material"
import React from "react"

const NavbarAlertIcon = (props) => {
  const { height, width, viewBox, fill, style, stroke, isSelected, ...other } = props
  return isSelected
    ? <SvgIcon style={style}>
      <svg width={width} height={height} viewBox={viewBox} fill={fill}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1087 3.01554C10.3787 1.66149 8.53609 1.66149 7.8059 3.01554L1.83643 14.0854C1.11184 15.4289 2.02744 17.1008 3.48788 17.1008H15.4268C16.8872 17.1008 17.8029 15.4289 17.0784 14.0854L11.1087 3.01554Z" fill="#4D44D8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6471 8.03119C10.6471 7.33547 10.1149 6.77148 9.45832 6.77148C8.80177 6.77148 8.26953 7.33547 8.26953 8.03119V10.0467C8.26953 10.7425 8.80177 11.3064 9.45832 11.3064C10.1149 11.3064 10.6471 10.7425 10.6471 10.0467V8.03119ZM9.45832 15.3375C10.1149 15.3375 10.6471 14.7734 10.6471 14.0778C10.6471 13.382 10.1149 12.8181 9.45832 12.8181C8.80177 12.8181 8.26953 13.382 8.26953 14.0778C8.26953 14.7734 8.80177 15.3375 9.45832 15.3375Z" fill="#BBC5FF"/>
      </svg>

    </SvgIcon>
    : <SvgIcon
      style={style}
    >
      <svg width={width} height={height} viewBox={viewBox} fill={fill}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1087 3.01554C10.3787 1.66149 8.53609 1.66149 7.8059 3.01554L1.83643 14.0854C1.11184 15.4289 2.02744 17.1008 3.48788 17.1008H15.4268C16.8872 17.1008 17.8029 15.4289 17.0784 14.0854L11.1087 3.01554Z" fill="#989898"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6471 8.03119C10.6471 7.33547 10.1149 6.77148 9.45832 6.77148C8.80177 6.77148 8.26953 7.33547 8.26953 8.03119V10.0467C8.26953 10.7425 8.80177 11.3064 9.45832 11.3064C10.1149 11.3064 10.6471 10.7425 10.6471 10.0467V8.03119ZM9.45832 15.3375C10.1149 15.3375 10.6471 14.7734 10.6471 14.0778C10.6471 13.382 10.1149 12.8181 9.45832 12.8181C8.80177 12.8181 8.26953 13.382 8.26953 14.0778C8.26953 14.7734 8.80177 15.3375 9.45832 15.3375Z" fill="#D3D3D3"/>
      </svg>
    </SvgIcon>
}

NavbarAlertIcon.defaultProps = {
  width: "19",
  height: "19",
  viewBox: "0 0 19 19",
  fill: "#8D9FB2",
  stroke: "",
  isSelected: false,
  style: {}
}

export default NavbarAlertIcon
