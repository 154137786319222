import { convertToZoneDate, convertToZoneDateNumeric } from "./DateConverter";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const monthsNumbers = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

const monthDictionary = 
  [
    {
      value: "Jan",
      fullName: "January",
      name: "01",
    },
    {
      value: "Feb",
      fullName: "February",
      name: "02",
    },
    {
      value: "Mar",
      fullName: "March",
      name: "03",
    },
    {
      value: "Apr",
      fullName: "April",
      name: "04",
    },
    {
      value: "May",
      fullName: "May",
      name: "05",
    },
    {
      value: "Jun",
      fullName: "June",
      name: "06",
    },
    {
      value: "Jul",
      fullName: "July",
      name: "07",
    },
    {
      value: "Aug",
      fullName: "August",
      name: "08",
    },
    {
      value: "Sep",
      fullName: "September",
      name: "09",
    },
    {
      value: "Oct",
      fullName: "October",
      name: "10",
    },
    {
      value: "Nov",
      fullName: "November",
      name: "11",
    },
    {
      value: "Dec",
      fullName: "December",
      name: "12",
    },
  ];

var maxd = new Date();
var maxutc = maxd.getTime() + maxd.getTimezoneOffset() * 60000;
var maxnd = new Date(maxutc + 3600000 * +5.5);

const minDate = new Date().setDate(new Date().getDate() - 60); //45
const maxDate = maxnd;
const minDateMinusOne = new Date().setDate(new Date().getDate() - 46);

const todayDate = new Date();
const tomorrowDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

let day = todayDate.getDate();
let month = todayDate.getMonth() + 1;
let year = todayDate.getFullYear();

let tday = tomorrowDate.getDate();
let tmonth = tomorrowDate.getMonth() + 1;
let tyear = tomorrowDate.getFullYear();

const todayDateNumeric = `${year}-${month}-${day}`
const tomorrowDateNumeric = `${tyear}-${tmonth}-${tday}`

function DateFormatString(date) {
  const year = date?.substring(0, 4);
  const month = months[monthsNumbers?.indexOf(date?.substring(5, 7))];
  const day = date?.substring(8, 10);

  const stringDate = day + " " + month + " " + year;
  return stringDate;
}

function DateFormatStringCompleteString(mydate) {
  var date = mydate?.toString();
  const year = date?.substring(11, 15);
  const month = date?.substring(4, 7);
  const day = date?.substring(8, 10);

  const stringDate = day + " " + month + " " + year;
  return stringDate;
}

function padTo2Digits(num) {
  return num?.toString()?.padStart(2, "0");
}
function formatDate(date) {
  return [
    padTo2Digits(date?.getDate()),
    date?.toLocaleString("en-us", { month: "short" }),
    date?.getFullYear(),
  ].join(" ");
}
function formatDateWithoutYear(date) {
  return [
    padTo2Digits(date?.getDate()),
    date?.toLocaleString("en-us", { month: "short" }),
    // date.getFullYear(),
  ].join(" ");
}
function formatDateNumeric(date) {
  return [
    date?.getFullYear(),
    padTo2Digits(date?.getMonth() + 1),
    padTo2Digits(date?.getDate()),
  ].join("-");
}

function DateDiffInDays(start, end) {
  const date1 = new Date(start);
  const date2 = new Date(end);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // console.log(diffDays + " days");
  return diffDays;
}

function returnLastSunday() {
  var now = new Date();
  var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  var lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));

  return lastSunday;
}

function getLastSundayYearMonth(year, month) {
  console.log(year, month);
  const lastDayOfMonth = new Date(year, month, 0);

  // Calculate the last day of the month's date and day of the week (0 for Sunday, 1 for Monday, etc.)
  const lastDayDate = lastDayOfMonth.getDate();
  const lastDayOfWeek = lastDayOfMonth.getDay();

  // Calculate the date of the last Sunday
  let lastSundayDate = lastDayDate - ((lastDayOfWeek + 1) % 7);

  // Handle the case where lastSundayDate becomes 0 (which is not a valid date)
  if (lastSundayDate <= 0) {
    // In this case, we need to move back to the last Sunday of the previous month
    const previousMonth = month - 1;
    const previousMonthLastDay = new Date(year, previousMonth, 0);
    lastSundayDate = previousMonthLastDay.getDate() - ((lastDayOfWeek + 1) % 7);
  }

  return new Date(year, month - 1, lastSundayDate);
}

function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return weekNo;
}

function getArrayOfNumericDates(start, end) {
  let tempStart = convertToZoneDateNumeric(start),
    tempEnd = convertToZoneDateNumeric(end);

  var dt = new Date(tempStart), dates = [];
  while (dt <= new Date(tempEnd)) {
    dates.push(formatDateNumeric(new Date(dt)));
    dt.setDate(dt.getDate() + 1)
  }

  return dates
}

function getArrayOfFormattedDates(start, end) {
  let tempStart = convertToZoneDateNumeric(start),
    tempEnd = convertToZoneDateNumeric(end);

  var dt = new Date(tempStart), dates = [];
  while (dt <= new Date(tempEnd)) {
    dates.push(formatDate(new Date(dt)));
    dt.setDate(dt.getDate() + 1)
  }

  return dates
}

function getWeekRangesForChart(startDate, endDate) {
  const weeks = [];
  let currentDate = new Date(startDate);
  const lastDate = new Date(endDate);

  while (currentDate <= lastDate) {
    const weekStart = new Date(currentDate);
    const weekEnd = new Date(currentDate);
    weekEnd.setDate(weekEnd?.getDate() + 6);
    weeks.push(`${formatDateWithoutYear(weekStart)} - ${formatDateWithoutYear(weekEnd)}`);
    currentDate.setDate(currentDate?.getDate() + 7);
  }

  return weeks;
}

const getFormattedResolutionTime = (activeDuration) => {

  const durations = [
    { label: 'Year', value: 60 * 24 * 365 },
    { label: 'Month', value: 60 * 24 * 30 },
    { label: 'Day', value: 60 * 24 },
    { label: 'Hour', value: 60 },
    { label: 'Minute', value: 1 }
  ];

  let remainingMinutes = activeDuration;
  const parts = [];

  durations.forEach(duration => {
    const durationValue = Math.floor(remainingMinutes / duration.value);
    if (durationValue > 0) {
      parts.push(`${durationValue} ${duration.label}${durationValue > 1 ? 's' : ''}`);
      remainingMinutes %= duration.value;
    }
  });

  return parts.join(', ');
}

export {
  DateFormatString,
  DateFormatStringCompleteString,
  getFormattedResolutionTime,
  minDate,
  maxDate,
  minDateMinusOne,
  monthDictionary,
  formatDate,
  formatDateWithoutYear,
  formatDateNumeric,
  DateDiffInDays,
  returnLastSunday,
  getLastSundayYearMonth,
  getWeekNumber,
  getArrayOfNumericDates,
  getArrayOfFormattedDates,
  todayDateNumeric,
  tomorrowDateNumeric,
  getWeekRangesForChart,
};
