import React, { useState } from "react";
import {
  Autocomplete,
  InputAdornment,
  Typography,
} from "@mui/material";
import { CustomTextField } from "../../Components/CustomTextField";
import { CustomChip } from "../../Components/CustomChip";

const validateEmail = (email, validDomains) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if the email is in a valid format
  if (!emailRegex.test(email)) {
    return false; // Invalid email format
  }

  // Extract the domain from the email
  const emailParts = email.split('@');
  if (emailParts.length !== 2 || emailParts[1].endsWith('.')) {
    return false; // Invalid domain
  }

  const emailDomain = `@${emailParts[1]}`;

  // Check if the email's domain is in the validDomains array
  return validDomains.includes(emailDomain);
};

function ToField(props) {
  const ccField = 'cc'
  const bccField = 'bcc'
  const toField = 'to'
  const [cc, setCc] = useState(false);
  const [bcc, setBcc] = useState(false);
  const [bccafter, setBccAfter] = useState(false);
  const [ccafter, setCcAfter] = useState(false);

  const handleCc = () => {
    setCc(!cc);
  };
  const handleBcc = () => {
    setBcc(!bcc);
  };
  const handleBccAfter = () => {
    setBccAfter(true);
  };
  const handleCcAfter = () => {
    setCcAfter(true);
  };

  const validateTag = (option, index, getTagProps, allTagsValue, fieldType) => {
    const isEmailValidated = validateEmail(option, props.availableEmailDomains)
    if (isEmailValidated) {
      return <CustomChip {...getTagProps({ index })} label={option} />
    }
    return undefined
  }

  const filterInvalidEmails = (emailList, fieldType) => {
    return emailList.filter((email, index) => {
      const isLastTag = index >= 0 && index === emailList.length - 1
      const isEmailValidated = validateEmail(email, props.availableEmailDomains)
      if (isLastTag) {
        if(isEmailValidated) {
          props.setHelperTextContent(props.defaultHelperTextContent)
          props.setHelperTextActiveOn(null)
          props.setHelperText(false)
        } else {
          props.setHelperTextContent("Invalid Email Address. Supported domains: " + props.availableEmailDomains.join(', '))
          props.setHelperTextActiveOn(fieldType)
          props.setHelperText(true)
        }
      }
      return isEmailValidated
    })
  }

  const changeTo = (value) => {
    if (value.trim() === "") {
      props.setHelperTextContent(props.defaultHelperTextContent)
      props.setHelperTextActiveOn(toField)
      props.setHelperText(true);
    } else {
      props.setHelperText(false);
    }

    // props.setTo([value.trim()]);
    props.setHelperText(false);
  };

  const onTagsChangeTo = (event, values) => {
    const validEmails = filterInvalidEmails(values, toField)
    if (values.length === 0) {
      props.setHelperTextContent(props.defaultHelperTextContent)
      props.setHelperTextActiveOn(toField)
      props.setHelperText(true);
    }
    props.setTo(validEmails);
  };

  const changeCc = (value) => {
    // props.setCC(value);
  };
  const onTagsChangeCC = (event, values) => {
    props.setCC(filterInvalidEmails(values, ccField));
  };

  const onTagsChangeBCC = (event, values) => {
    props.setBCC(filterInvalidEmails(values, bccField));
  };
  const changeBcc = (value) => {
    // props.setBCC(value);
  };

  return (
    <div>
      <div
        style={{
          padding: "5px 0px",
        }}
      >
        {props.helperText && props.helperTextActiveOn === toField ? (
          <Autocomplete
            multiple
            id="tags-filled"
            freeSolo
            ListboxProps={{
              sx: { fontSize: 13, fontWeight: 500 },
            }}
            options={props.actualTo}
            componentsProps={{
              paper: {
                sx: {
                  width: 300,
                },
              },
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                validateTag(option, index, getTagProps, tagValue, toField)
              ));
            }}
            onChange={onTagsChangeTo}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="standard"
                placeholder="Type Email and press Enter"
                error={true}
                helperText={props.helperTextContent}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                      <Typography variant="textSubtitle">To:</Typography>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {!cc && !bcc && (
                        <div style={{ display: "flex"}}>
                          <Typography variant="textSubtitle" onClick={handleCc} sx={{cursor:"pointer"}}>
                            Cc
                          </Typography>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <Typography
                            variant="textSubtitle"
                            onClick={handleBcc}
                            sx={{cursor:"pointer"}}
                          >
                            Bcc
                          </Typography>
                        </div>
                      )}
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style:{
                    fontSize:10
                  }
                }}
                sx={{ input: { fontSize:12 }}} 
              />
            )}
          />
        ) : (
          <Autocomplete
            multiple
            freeSolo
            ListboxProps={{
              sx: { fontSize: 13, fontWeight: 500 },
            }}
            id="tags-outlined"
            options={props.actualTo}
            componentsProps={{
              paper: {
                sx: {
                  width: 300,
                },
              },
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                validateTag(option, index, getTagProps, tagValue, toField)
              ));
            }}
            onChange={onTagsChangeTo}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="standard"
                placeholder="Type Email and Press Enter"
                sx={{ fontSize: 15, fontWeight: 500, fontFamily: "Roboto", input: { fontSize:12 } }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <Typography variant="textSubtitle">To:</Typography>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {!cc && !bcc && (
                        <div style={{ display: "flex"}}>
                          <Typography variant="textSubtitle" onClick={handleCc} sx={{cursor:"pointer"}}>
                            Cc
                          </Typography>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <Typography
                            variant="textSubtitle"
                            onClick={handleBcc}
                            sx={{cursor:"pointer"}}
                          >
                            Bcc
                          </Typography>
                        </div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        )}
      </div>

      {ccafter && (
        <div
          style={{
            padding: "5px 0px",
          }}
        >
          <Autocomplete
            multiple
            freeSolo
            id="tags-outlined"
            options={props.actualTo}
            sx={{ input: { fontSize:12 }}} 
            ListboxProps={{
              sx: { fontSize: 13, fontWeight: 500 },
            }}
            componentsProps={{
              paper: {
                sx: {
                  width: 300,
                },
              },
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                validateTag(option, index, getTagProps, tagValue, ccField)
              ));
            }}
            onChange={onTagsChangeCC}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="standard"
                placeholder="Type Email and press Enter"
                error={props.helperText && props.helperTextActiveOn === ccField}
                helperText={props.helperText && props.helperTextActiveOn === ccField ? props.helperTextContent : null}
                InputProps={{
                  ...params.InputProps,
                  //className: formClasses.input,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                      <Typography variant="textSubtitle">Cc:</Typography>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                FormHelperTextProps={{
                  style:{
                    fontSize:10
                  }
                }}
                sx={{ input: { fontSize:12 }}} 
              />
            )}
          />
        </div>
      )}

      {cc && !ccafter && (
        <div
          style={{
            padding: "5px 0px",
          }}
        >
          <Autocomplete
            multiple
            freeSolo
            id="tags-outlined"
            sx={{ input: { fontSize:12 }}} 
            options={props.actualTo}
            ListboxProps={{
              sx: { fontSize: 13, fontWeight: 500 },
            }}
            componentsProps={{
              paper: {
                sx: {
                  width: 300,
                },
              },
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                validateTag(option, index, getTagProps, tagValue, bccField)
              ));
            }}
            onChange={onTagsChangeCC}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="standard"
                placeholder="Type Email and press Enter"
                error={props.helperText && props.helperTextActiveOn === ccField}
                helperText={props.helperText && props.helperTextActiveOn === ccField ? props.helperTextContent : null}
                InputProps={{
                  ...params.InputProps,
                  //className: formClasses.input,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                      <Typography variant="textSubtitle">Cc:</Typography>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {!bcc && (
                        <div style={{ display: "flex",}}>
                          <Typography
                          variant="textSubtitle"
                            onClick={() => {
                              handleBccAfter();
                              handleBcc();
                            }}
                            sx={{cursor:"pointer"}}
                          >
                            Bcc
                          </Typography>
                        </div>
                      )}
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style:{
                    fontSize:10
                  }
                }}
              />
            )}
          />
        </div>
      )}

      {bcc && !bccafter && (
        <div
          style={{
            padding: "5px 0px",
          }}
        >
          <Autocomplete
            multiple
            id="tags-outlined"
            freeSolo
            options={props.actualTo}
            sx={{ input: { fontSize:12 }}} 
            ListboxProps={{
              sx: { fontSize: 13, fontWeight: 500 },
            }}
            componentsProps={{
              paper: {
                sx: {
                  width: 300,
                },
              },
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                validateTag(option, index, getTagProps, tagValue)
              ));
            }}
            onChange={onTagsChangeBCC}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="standard"
                error={props.helperText && props.helperTextActiveOn === bccField}
                helperText={props.helperText && props.helperTextActiveOn === bccField ? props.helperTextContent : null}
                placeholder="Type Email and press Enter"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                      <Typography variant="textSubtitle">Bcc:</Typography>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {!cc && (
                        <div style={{ display: "flex" }}>
                          <Typography
                          variant="textSubtitle"
                            onClick={() => {
                              handleCcAfter();
                              handleCc();
                            }}
                            sx={{cursor:"pointer"}}
                          >
                            Cc
                          </Typography>
                        </div>
                      )}
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style:{
                    fontSize:10
                  }
                }}
              />
            )}
          />
        </div>
      )}

      {bccafter && (
        <div
          style={{
            padding: "5px 0px",
          }}
        >
          <Autocomplete
            multiple
            freeSolo
            id="tags-outlined"
            options={props.actualTo}
            sx={{ input: { fontSize:12 }}} 
            ListboxProps={{
              sx: { fontSize: 13, fontWeight: 500 },
            }}
            componentsProps={{
              paper: {
                sx: {
                  width: 300,
                },
              },
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                validateTag(option, index, getTagProps, tagValue)
              ));
            }}
            onChange={onTagsChangeBCC}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="standard"
                placeholder="Type Email and press Enter"
                error={props.helperText && props.helperTextActiveOn === bccField}
                helperText={props.helperText && props.helperTextActiveOn === bccField ? props.helperTextContent : null}
                InputProps={{
                  ...params.InputProps,
                  //className: formClasses.input,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                      <Typography variant="textSubtitle">Bcc:</Typography>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                FormHelperTextProps={{
                  style:{
                    fontSize:10
                  }
                }}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}

export default ToField;
