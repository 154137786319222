import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Cookies from 'universal-cookie';

dayjs.extend(utc);
dayjs.extend(timezone);
const cookies = new Cookies();
dayjs.tz.setDefault(cookies.get("timeZone"));

const calculateGranularity = (calendarStartDate, calendarEndDate, previousGranularity=null, defaultHighLevelGranularity="weekly") => {
  const startDate = dayjs(calendarStartDate);
  const endDate = dayjs(calendarEndDate);
  const differenceInDays = endDate.diff(startDate, 'day');
  
  return differenceInDays > 31 ? defaultHighLevelGranularity : (previousGranularity || 'daily')
}

export {
  calculateGranularity
}
