import { Box, ListItem, styled } from "@mui/material"
import { CustomText, FlexSpaceBetweenBox, FlexStartBox } from "./CustomBox"
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const LastUpdatedTimeBox = styled(FlexSpaceBetweenBox)({
  alignItems: "center",
  marginRight: "8px"
})

const LastUpdatedTimeText = styled(CustomText)({
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "18px",
  color: "#1C1C1C"
})

const LastUpdatedTimeStampText = styled(CustomText)({
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "18px",
  color: "#1C1C1C",
  marginLeft: "7px"
})

const NavAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ expanded, theme }) => ({
  margin: 0,
  borderLeft: 0,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const ShowMoreIcon = styled(ExpandMoreIcon)((props) => ({
  fontSize: "1.5rem",
  paddingRight: "12px",
}))
const ShowLessIcon = styled(ExpandLessIcon)((props) => ({
  fontSize: "1.5rem",
  paddingRight: "12px",
}))

const NavAccordionSummary = styled(
  ({ isExpanded, isSelected, selected, filterOptions, showExpandIcon, ...props }) => (
    <MuiAccordionSummary
      expandIcon={
        showExpandIcon 
          ? isExpanded 
            ? <ShowLessIcon sx={{color: isSelected ? "#6D72F6" : "#4E4F6A"}}/> 
            : <ShowMoreIcon sx={{color: isSelected ? "#6D72F6" : "#4E4F6A"}}/> 
          : null
      }
      {...props}
    />
  )
)(({ isSelected, isHover }) => ({
  backgroundColor: isSelected || isHover ? "#F4F5FF" : "#FFF",
  borderRadius: "5px",
  alignItems: "center",
  marginBottom: "3px",
  padding: 0,
  minHeight: 0,
  "& .MuiAccordionSummary-expandIconWrapper": {
    overflow: "hidden",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    margin: 0,
    transform: "none",
    backgroundColor: "transparent",
    overflow: "hidden",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "9px",
    padding: "8px 0",
    overflow: "hidden",
  },
  "& .MuiAccordionSummary-root": {
    alignItems: "center",
  },
}));

const NavAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "8px 0 8px 20px",
}));

const NavAccordionListItemBox = styled(Box)({
  cursor: "pointer",
  borderRadius: "5px",
  "&:hover": {
    backgroundColor: "#F4F4F4",
  },
  padding: "8px 0 8px 25px",
});

const NavAccordionListItemText = styled(CustomText)(({ isSelected }) => ({
  fontWeight: "400",
  fontSize: "14px",
  textDecoration: "none",
  color: isSelected ? "#6D72F6" : "#4E4F6A"
}));

const NavListItemText = styled(CustomText)(({ isSelected }) => ({
  fontWeight: isSelected ? "500" : "400",
  fontSize: "14px",
  color: isSelected ? "#6D72F6" : "#4E4F6A",
  marginLeft: "17px",
}));

const NavListItemBox = styled(ListItem)(({ props }) => ({
  margin: 0, 
  padding: 0,
  marginLeft: "8px",
  display: "flex",
  alignItems: "center"
}));

const NavListItemContainer = styled(ListItem)(({ props }) => ({
  width: "100%",
  marginBottom: "8px",
}));

export { 
  LastUpdatedTimeText,
  LastUpdatedTimeBox,
  LastUpdatedTimeStampText,
  NavAccordion,
  NavAccordionSummary,
  NavAccordionDetails,
  NavAccordionListItemText,
  NavAccordionListItemBox,
  NavListItemText,
  NavListItemBox,
  NavListItemContainer
}
