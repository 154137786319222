import React, { useEffect } from "react"
import Loader from "../../Helper/Loaders/Loader"
import { usePageMeta } from "../../Hooks/usePageMeta"
import { BatteryHealthLoaderBox, PageContainer } from "../../Components/CustomBox"
import { NotificationProvider } from "../../Hooks/useNotification"
import ChargerDrillDownMain from "./ChargerDrillDownMain"
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt"

function ChargerDrillDown(props) {
  const { pagesContent } = usePageMeta("chargerDrillDown");
  const queryParams = decryptTheParams()

  useEffect(() => {
    pagesContent["mounted"] = true
  }, [pagesContent])

  return (
    <PageContainer>
      {(pagesContent?.mounted && queryParams?.chartName) ? (
        <NotificationProvider>
          <ChargerDrillDownMain pagesContent={pagesContent} />
        </NotificationProvider>
      ) : (
        <BatteryHealthLoaderBox>
          <Loader />
        </BatteryHealthLoaderBox>
      )}
    </PageContainer>
  )
}

export default ChargerDrillDown
