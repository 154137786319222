import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import Events from "../../../Analytics/Events";
import CustomIcon from "../../../Components/CustomIcon";
import CardWithHeader from "../../../Features/Cards/CardWithHeader";
import { useNotification } from "../../../Hooks/useNotification";
import { getMetricTrendData } from "../../../Api/AlertAnalyticsApi";
import CODE from "../../../Static/Constants/StatusCodes";
import { findYAxisPartitionSizeForPositiveAxis, getXAxisDateString } from "../../../Helper/Charts/ChartHelper";
import AnalyticsBasicLine from "../../../Charts/Line/AnalyticsBasicLine";
import { toTitleCase } from "../../../Helper/Formatters/TextFormatter";
import { getFilterData } from "../../../Api/Api";
import { decryptTheParams, encryptTheParams, updateQueryParams } from "../../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { formatDateWithoutYear } from "../../../Helper/DatePicker/DateFormatters";
import moment from "moment";

const defaultChartState = {
  series: [],
  statusCode: CODE.LOADING
}

const FleetAlertStatus = ({ filters, startDate, endDate, granularity, cardSubtitleTimePeriodText, clickableHeader, chartSelectedFilter }) => {
  const navigate = useNavigate()
  const { openNotification, closeNotification } = useNotification();
  const [chartState, setChartState] = useState(defaultChartState);
  const [maxAlertCount, setMaxAlertCount] = useState(0);
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);
  const isFilterMounted = useSelector((state) => state.IsFiltersMounted.value);
  const [cardFilterData, setCardFilterData] = useState({
    menuItemData: [],
    selectedItemId: null,
  })
  const [isCardFilterMounted, setIsCardFilterMounted] = useState(false)
  const [csvData, setCsvData] = useState({
    data: [],
    responseStatus: { code: null }
  })

  const yAxisSetting = {
    title: {
      text: 'Alert Count',
      style: {
        color: "#87939D",
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
      },
    },
    showAlways: true,
    decimalsInFloat: 1,
    decimalPlaces: 0,
    axisBorder: {
      show: false,
      color: '#87939D',
      offsetX: -2,
      offsetY: 0
    },
    labels: {
      style: {
          colors: '#87939D',
          fontSize: '10px',
          fontFamily: 'Roboto',
          fontWeight: 500,
      },
    },
    tickAmount: 5,
    min: 0,
    max: findYAxisPartitionSizeForPositiveAxis(maxAlertCount, 5) * 5
  }

  useEffect(() => {
    fetchCardFilterData()
  }, [])

  useEffect(() => {
    if (isCardFilterMounted && isFilterMounted) {
      fetchData();
      chartSelectedFilter(isCardFilterMounted, cardFilterData.selectedItemId, 'alertType')
    }
  }, [
    granularity,
    filters,
    cardFilterData.selectedItemId
  ])

  const fetchData = async () => {
    setChartState(defaultChartState);
    const res = await getMetricTrendData(["active", "resolved", "expired"], startDate, endDate, granularity.granularity, {...filters, alertType: [cardFilterData?.selectedItemId]}, defaultFilterValues)
    const { responseStatus, response } = res;
    
    if (responseStatus.code !== CODE.SUCCESS) {
      setChartState({
        ...defaultChartState,
        statusCode: CODE.NODATA
      });
      return
    }

    generateChartData(response, responseStatus);
  }

  const fetchCardFilterData = async () => {
    const filterConfig = {
      "queryParams": { "table": "alertMeta", "column": "alertType"}
    }
    const res = await getFilterData(filterConfig);
    if (res.responseStatus.code === CODE.SUCCESS) {
      const data = res.response.response
      const queryParamsCardFilterValue = decryptTheParams()?.fleetAlertStatusCardFilterValue
      setCardFilterData({
        menuItemData: data.filterData.map(filter => ({...filter, value: filter.value + " Alerts"})),
        selectedItemId: queryParamsCardFilterValue || data.filterData?.[0]?.id
      });
      setIsCardFilterMounted(true)
    }
  }

  const generateChartData = (response, responseStatus) => {
    const dataLength = response.data?.length;
    const metrics = [
      { key: "active", name: "Active Alerts", color: "#FBC562" },
      { key: "resolved", name: "Resolved Alerts", color: "#28927D" },
      { key: "expired", name: "Expired Alerts", color: "#AAA594"},
    ];
    let maxCount = 0;
    const series = metrics.map((metric) => {
      return {
          name: metric.name,
          color: metric.color,
          type: 'line',
          data: response.data.map(data => {
            const value = data.metricsArray.find(item => item.type === metric.key)?.value
            maxCount = Math.max(maxCount, value)
            let x, dateRangeString
            if (granularity.granularity === 'daily') {
              const date = new Date(data.range.startDate);
              x = formatDateWithoutYear(date)
              dateRangeString = moment(data.range.startDate).format('DD MMM, YYYY')
            } else {
              const result = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity, true)
              x = result.x;
              dateRangeString = result.dateRangeString;
            }
            return {
                x,
                y: value,
                dateRangeString
            }
          })
      }
    })

    setMaxAlertCount(maxCount)
    setChartState({
      series: series, 
      statusCode: responseStatus.code
    })
  }

  const generateCsvData = () => {
    if (chartState.statusCode !== CODE.SUCCESS) {
      return
    }

    const activeAlertData = chartState.series.find(series => series.name === "Active Alerts")?.data || []
    const resolvedAlertData = chartState.series.find(series => series.name === "Resolved Alerts")?.data || []
    const expiredAlertData = chartState.series.find(series => series.name === "Expired Alerts")?.data || []

    const data = [
      ...activeAlertData.map((data, index) => ({
        "Date Range": data.dateRangeString?.replace(/,/g, " "),
        "Active Alert Count": data.y,
        "Resolved Alert Count": resolvedAlertData?.[index]?.y,
        "Expired Alert Count": expiredAlertData?.[index]?.y,
      })),
    ];

    setCsvData({
      data,
      responseStatus: { code: CODE.SUCCESS }
    });
  }

  const handleFilterChange = (event, selectedItem) => {
    setCardFilterData((prev) => ({
      ...prev,
      selectedItemId: selectedItem.props.value
    }))
    updateQueryParams(navigate, {fleetAlertStatusCardFilterValue: selectedItem.props.value})
  }

  const onClickHeader = () => {
    const temp = {
      chartName: 'fleetAlertStatus',
      startDate,
      endDate,
      fleetAlertStatusCardFilterValue: cardFilterData.selectedItemId
    };
    encryptTheParams(temp, navigate, false, '/AlertDrillDown', true);
  }
  
  return (
    <CardWithHeader
      id='fleetAlertStatus'
      headerClass="header-hover"
      title="Fleet Alert Status"
      subtitle={`${cardSubtitleTimePeriodText}`}
      iconElement={<CustomIcon name="barGraph" style={{ width: 21, height: 21 }}/>}
      pageLabel="Alert Analytics"
      showCSVDownload
      csvData={csvData}
      csvName={"fleetAlertStatus"}
      generateCsvData={generateCsvData}
      onMouseEnter={() =>
        Events("Alert Analytics: Hover over Fleet Alert Status chart")
      }
      openNotification={openNotification}
      closeNotification={closeNotification}
      cardStyle={{ height: "464px" }}
      bodyStyle={{ height:"363px", position: "relative", paddingLeft: "20px", paddingRight: chartState.statusCode === CODE.SUCCESS ? "22px" :  "35px" }}
      hasFilter
      filterData={cardFilterData}
      onFilterChange={handleFilterChange}
      clickableHeader={clickableHeader}
      onClickHeader={onClickHeader}
    >
      <AnalyticsBasicLine
        hideAlternateLabels={granularity.granularity === "daily" && chartState.series?.[0]?.data?.length > 15}
        state={chartState.statusCode}
        series={chartState.series}
        yAxisSetting={yAxisSetting}
        granularity={toTitleCase(granularity.granularity)}
      />
    </CardWithHeader>
  )
}

FleetAlertStatus.defaultProps = {
  clickableHeader: false,
  chartSelectedFilter: () => {}
}

export default FleetAlertStatus;
