import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import Events from "../../../Analytics/Events";
import CustomIcon from "../../../Components/CustomIcon";
import CardWithHeader from "../../../Features/Cards/CardWithHeader";
import { useNotification } from "../../../Hooks/useNotification";
import { getMetricTrendData } from "../../../Api/AlertAnalyticsApi";
import CODE from "../../../Static/Constants/StatusCodes";
import AnalyticsBasicLine from "../../../Charts/Line/AnalyticsBasicLine";
import { convertChartValueToResolutionTimeFormat, findYAxisPartitionSizeForPositiveAxis, getXAxisDateString, nextDivisibleBy } from "../../../Helper/Charts/ChartHelper";
import { getFilterData } from "../../../Api/Api";
import { toTitleCase } from "../../../Helper/Formatters/TextFormatter";
import { useNavigate } from "react-router-dom";
import { decryptTheParams, encryptTheParams, updateQueryParams } from "../../../Helper/QueryParams/EncryptDecrypt";
import { formatDateWithoutYear } from "../../../Helper/DatePicker/DateFormatters";
import moment from "moment";

const defaultChartState = {
  series: [],
  statusCode: CODE.LOADING
}

const FleetAvgResolutionTimeChart = ({ filters, startDate, endDate, granularity, cardSubtitleTimePeriodText, clickableHeader, chartSelectedFilter }) => {
  const navigate = useNavigate()
  const { openNotification, closeNotification } = useNotification();
  const [chartState, setChartState] = useState(defaultChartState);
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);
  const isFilterMounted = useSelector((state) => state.IsFiltersMounted.value);
  const [cardFilterData, setCardFilterData] = useState({
    menuItemData: [],
    selectedItemId: null,
  })
  const [isCardFilterMounted, setIsCardFilterMounted] = useState(false)
  const [csvData, setCsvData] = useState({
    data: [],
    responseStatus: { code: null }
  })

  const defaultYAxisSetting = {
    showAlways: true,
    decimalsInFloat: 0,
    decimalPlaces: 0,
    axisBorder: {
      show: false,
      color: '#87939D',
      offsetX: -2,
      offsetY: 0
    },
    labels: {
      style: {
          colors: '#87939D',
          fontSize: '10px',
          fontFamily: 'Roboto',
          fontWeight: 500,
      },
    },
    tickAmount: 5,
    isResolutionTimeFormat: false,
    min: 0,
    max: (max) => findYAxisPartitionSizeForPositiveAxis(max, 5) * 5
  }

  const yAxisSetting = [
    {
      ...defaultYAxisSetting, 
      title: {
        text: 'Resolved Alert Count',
        style: {
          color: "#87939D",
          fontSize: '12px',
          fontFamily: 'Roboto',
          fontWeight: 400,
        },
      }
    },
    {
      ...defaultYAxisSetting, 
      opposite: true,
      title: {
        text: 'Avg Resolution Time(Days &Hours)',
        style: {
          color: "#87939D",
          fontSize: '12px',
          fontFamily: 'Roboto',
          fontWeight: 400,
        },
      },
      isResolutionTimeFormat: true,
      labels: {
        style: {
            colors: '#87939D',
            fontSize: '10px',
            fontFamily: 'Roboto',
            fontWeight: 500,
        },
        formatter: (value) => {
          return convertChartValueToResolutionTimeFormat(value)
        }
      },
      max: (max) => nextDivisibleBy(max, 300)
    },
  ]

  useEffect(() => {
    fetchCardFilterData()
  }, [])

  useEffect(() => {
    if (isCardFilterMounted && isFilterMounted) {
      fetchData();
      chartSelectedFilter(isCardFilterMounted, cardFilterData.selectedItemId, 'alertMetaID')
    }
  }, [
    granularity,
    filters,
    cardFilterData.selectedItemId
  ])

  const fetchData = async () => {
    setChartState(defaultChartState);
    const res = await getMetricTrendData(["resolved", "resolutionTime"], startDate, endDate, granularity.granularity, {...filters, alertMetaID: [cardFilterData?.selectedItemId]}, defaultFilterValues)
    const { responseStatus, response } = res;
    
    if (responseStatus.code !== CODE.SUCCESS) {
      setChartState({
        ...defaultChartState,
        statusCode: CODE.NODATA
      });
      return
    }

    generateChartData(response, responseStatus);
  }

  const fetchCardFilterData = async () => {
    const filterConfig = {
      "queryParams": { "table": "alertMeta", "column": "alertDescription", "keyColumn": "id"}
    }
    const res = await getFilterData(filterConfig);
    if (res.responseStatus.code === CODE.SUCCESS) {
      const data = res.response.response
      const queryParamsCardFilterValue = decryptTheParams()?.fleetAvgResolutionTimeCardFilterValue
      setCardFilterData({
        menuItemData: data.filterData,
        selectedItemId: queryParamsCardFilterValue ? parseInt(queryParamsCardFilterValue) : data.filterData?.[0]?.id
      });
      setIsCardFilterMounted(true)
    }
  }

  const generateChartData = (response, responseStatus) => {
    const dataLength = response.data?.length;
    const metrics = [
      {key: "resolved", name: "Resolved Alert Count", color: "#FAC563",}, 
      {key: "resolutionTime", name: "Average Resolution Time",color: "#29937D"}, 
    ]
    const series = metrics.map((metric) => {
      return {
        name: metric.name,
        color: metric.color,
        type: 'line',
        data: response.data.map(data => {
          const value = data.metricsArray.find(item => item.type === metric.key)?.value
          
          let x, dateRangeString
          if (granularity.granularity === 'daily') {
            const date = new Date(data.range.startDate);
            x = formatDateWithoutYear(date)
            dateRangeString = moment(data.range.startDate).format('DD MMM, YYYY')
          } else {
            const result = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity, true)
            x = result.x;
            dateRangeString = result.dateRangeString;
          }
          return {
              x,
              y: value,
              dateRangeString
          }
        })
      }
    })

    setChartState({
      series: series, 
      statusCode: responseStatus.code
    })
  }

  const generateCsvData = () => {
    if (chartState.statusCode !== CODE.SUCCESS) {
      return
    }
    const alertCountData = chartState.series.find(series => series.name === "Resolved Alert Count")?.data || []
    const resolutionTimeData = chartState.series.find(series => series.name === "Average Resolution Time")?.data || []

    const data = [
      ...alertCountData.map((data, index) => ({
        "Date Range": data.dateRangeString?.replace(/,/g, " "),
        "Resolved Alert Count": data.y,
        "Average Resolution Time": convertChartValueToResolutionTimeFormat(resolutionTimeData?.[index]?.y),
      })),
    ];

    setCsvData({
      data,
      responseStatus: { code: CODE.SUCCESS }
    });
  }

  const handleFilterChange = (event, selectedItem) => {
    setCardFilterData((prev) => ({
      ...prev,
      selectedItemId: selectedItem.props.value
    }))
    updateQueryParams(navigate, {fleetAvgResolutionTimeCardFilterValue: selectedItem.props.value})
  }

  const onClickHeader = () => {
    const temp = {
      chartName: 'fleetAvgResolutionTime',
      startDate,
      endDate,
      fleetAvgResolutionTimeCardFilterValue: cardFilterData.selectedItemId
    };
    encryptTheParams(temp, navigate, false, '/AlertDrillDown', true);
  }
  
  return (
    <CardWithHeader
      id='fleetAvgResolutionTimeChart'
      headerClass="header-hover"
      title="Fleet Avg. Resolution Time for each alert  "
      subtitle={`${cardSubtitleTimePeriodText}`}
      iconElement={<CustomIcon name="barGraph" style={{ width: 21, height: 21 }}/>}
      pageLabel="Alert Analytics"
      showCSVDownload
      csvData={csvData}
      csvName={"FleetAvgResolutionTime"}
      generateCsvData={generateCsvData}
      onMouseEnter={() =>
        Events("Alert Analytics: Hover over Fleet Avg Resolution Time chart")
      }
      openNotification={openNotification}
      closeNotification={closeNotification}
      cardStyle={{ height: "464px" }}
      bodyStyle={{ height:"363px", position: "relative", paddingLeft: 0, paddingRight: "6px" }}
      hasFilter
      filterData={cardFilterData}
      onFilterChange={handleFilterChange}
      clickableHeader={clickableHeader}
      onClickHeader={onClickHeader}
    >
      <AnalyticsBasicLine
        hideAlternateLabels={granularity.granularity === "daily" && chartState.series?.[0]?.data?.length > 15}
        state={chartState.statusCode}
        series={chartState.series}
        yAxisSetting={yAxisSetting}
        granularity={toTitleCase(granularity.granularity)}
      />
    </CardWithHeader>
  )
}

FleetAvgResolutionTimeChart.defaultProps = {
  clickableHeader: false,
  chartSelectedFilter: () => {}
}

export default FleetAvgResolutionTimeChart;
