import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material"
import GenericMultipleSelect from "./GenericMultipleSelect"
import { FilterContainer, ResetFilterBox } from "../../Components/CustomBox"
import { areArraysEqual } from "../../Helper/Operations/ArrayOps"
import { SetFilterDefaultValues, SetFilterValues, SetIsFiltersMounted } from "../../Actions/Filters"
import { decryptTheParams, encryptTheParams, updateQueryParams } from "../../Helper/QueryParams/EncryptDecrypt"
import { extractArrayValuesForFilters } from "../../Helper/Operations/GetFilterKeyValue";
import CODE from "../../Static/Constants/StatusCodes";
import { getFilterData } from "../../Api/Api";
import CustomMultiSelectDropdown from "../../Components/CustomMultiSelectDropdown";
import AccordionTypeMultiSelect from "../../Components/AccordionTypeMultiSelect";
import { SetTablePageNumber } from "../../Actions/Table";


GenericFilterContainer.defaultProps = {
  filterType: "primary",
  showResetButton: true,
  handleAccordionChange: () => {},
  onSelectFilter: () => {},
  onResetFilters: () => {}
}

export default function GenericFilterContainer(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filtersConfig = props.filtersConfig || []
  const [resetButton, setResetButton] = useState(false);
  const [filtersOptions, setFilterOptions] = useState({});
  const selectedFilters = useSelector((state) => state.SelectedFilterValues.value);
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);

  useEffect(() => {
    fetchFiltersData();
  }, [])

  useEffect(() => {
    showResetButton()
  }, [selectedFilters])

  const fetchFiltersData = async () => {
    let filtersData = {};
    
    if (!filtersConfig.length) {
      dispatch(SetIsFiltersMounted(true))
      return filtersData
    }

    await Promise.all(
      filtersConfig.map(async (filter) => {
        if (filtersData[filter.key]) {
          return;
        }

        if (filter.type === 'static') {
          filtersData[filter.key] = filter.staticData;
          return;
        }

        const res = await getFilterData(filter);
        if (res && res?.responseStatus?.code === CODE.SUCCESS) {
          filtersData[filter.key] = res.response.response.filterData;
        }
      })
    );
    setFilterOptions(filtersData)
    
    const defaultFilters = extractArrayValuesForFilters(filtersData)
    let selectedFiltersValues = defaultFilters
    const queryParams = decryptTheParams()
    if (queryParams.filters) {
      selectedFiltersValues = JSON.parse(queryParams.filters)
    }
    dispatch(SetFilterValues(selectedFiltersValues))
    dispatch(SetFilterDefaultValues(defaultFilters))
    dispatch(SetIsFiltersMounted(true))
    return filtersData;
  };

  const handleFilterValueChange = (key, label, selectedValue) => {
    const newSelectedValues = {...selectedFilters}
    const queryParams = decryptTheParams();
    
    dispatch(SetTablePageNumber(1))
    newSelectedValues[key] = selectedValue
    dispatch(SetFilterValues(newSelectedValues))
    props.onSelectFilter(key, label, selectedValue)
    
    const temp = {
      ...queryParams,
      filters: JSON.stringify(newSelectedValues)
    };
    encryptTheParams(temp, navigate);
  }

  const resetFilters = () => {
    dispatch(SetTablePageNumber(1))
    dispatch(SetFilterValues(defaultFilterValues))
    setResetButton(false);
    updateQueryParams(navigate, {
      filters: ""
    })
    props.onResetFilters()
  }

  const showResetButton = () => {
    const allAvailableFilters = props.filtersConfig || []
    for (let filterConfig of allAvailableFilters) {
      if (!areArraysEqual(defaultFilterValues?.[filterConfig.key] || [], selectedFilters?.[filterConfig.key] || [])) {
        setResetButton(true);
        return
      }
    }

    setResetButton(false);
  }

  const getMultiSelectDropdownFilter = (data, filterIndex) => {
    if (props.filterType === 'accordionMultiSelect') {
      return <AccordionTypeMultiSelect 
        filterConfig={data}
        filterOptions={filtersOptions[data.key] || []}
        name={`filter${filterIndex}`}
        isExpanded={props.expandedAccordion === `filter${filterIndex}`}
        onAccordionChange={props.handleAccordionChange}
        handleSetFilter={(label, value) => {
          handleFilterValueChange(data.key, label, value)
        }}
        optionValue={selectedFilters?.[data.key] || []}
      />
    }
    
    const MultiSelectComponent = props.filterType === 'analytics' ? CustomMultiSelectDropdown : GenericMultipleSelect
    
    return <MultiSelectComponent
      label={data?.label}
      options={filtersOptions[data.key]}
      maxWidth={"100px"}
      iconName={data?.iconName}
      isFirstFilter={filterIndex === 0}
      optionValue={selectedFilters?.[data.key] || []}
      handleSetFilter={(label, value) => {
        handleFilterValueChange(data.key, label, value)
      }}
      id="overflow-text-3"
    />
  }

  const renderFilter = (filterData, filterIndex) => {
    switch (filterData.displayType) {
      case "multiSelectDropdown":
        return getMultiSelectDropdownFilter(filterData, filterIndex)
    }
  }

  return (
    <FilterContainer filterType={props.filterType}>
      {props.showResetButton && resetButton && (
        <ResetFilterBox
          onClick={resetFilters}
        >
          Reset
        </ResetFilterBox>
      )}
      {filtersConfig.map((filter, index) => { 
        if (filter.tabs) {
          return filter.tabs.includes(props.activeTabValue) ? (
            <Box key={index}>{renderFilter(filter, index)}</Box>
          ) : null
        }

        return <Box key={index}>{renderFilter(filter, index)}</Box>
      })}
    </FilterContainer>
  )
}
