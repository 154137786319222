import { SvgIcon } from "@mui/material"
import React from "react"

const CheckedBoxSvg = (props) => {
  const { height, width, viewBox, fill, stroke, ...other } = props
  return (
    <SvgIcon style={{ ...props.style }}>
      <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" fill={fill} stroke={stroke}/>
        <path d="M4 7L6.14645 9.14645C6.34171 9.34171 6.65829 9.34171 6.85355 9.14645L11 5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </SvgIcon>
  )
}

CheckedBoxSvg.defaultProps = {
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "#6D72F6",
  stroke: "#6D72F6"
}

export default CheckedBoxSvg
