import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import FleetAlertDistributionChart from "./Charts/FleetAlertDistributionChart";
import { FilterAndCalendarContainer, ChartSectionContainer } from "./Components/StyledComponent";
import FleetAlertStatus from "./Charts/FleetAlertStatus";
import FleetSeverityDistributionChart from "./Charts/FleetSeverityDistributionChart";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { rangePresets } from "../../Static/Data/Analytics/Analytics";
import { analyticsDisabledDate, getCurrentMonthStartAndEndDate, getDatePickerDate, getPreviousMonthDate } from "../../Helper/DatePicker/DateConverter";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { updateQueryParams } from "../../Helper/QueryParams/EncryptDecrypt";
import FilterWithCalendar from "../../Components/FilterWithCalendar";
import { SetAlertAnalyticsEndDate, SetAlertAnalyticsStartDate } from "../../Actions/AlertAnalytics";
import FleetAvgResolutionTimeChart from "./Charts/FleetAvgResolutionTimeChart";

function ChartsSection({ pagesContent }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault(cookies.get("timeZone"));

  const defaultStartDate = getPreviousMonthDate(getDatePickerDate(undefined, -1))
  const defaultEndDate = getDatePickerDate(undefined, -1)
  const calendarStartDate = useSelector((state) => state.AlertAnalyticsStartDate.value) || defaultStartDate;
  const calendarEndDate = useSelector((state) => state.AlertAnalyticsEndDate.value) || defaultEndDate;
  const selectedFilters = useSelector((state) => state.SelectedFilterValues.value)
  const [granularity, setGranularity] = useState({granularity: 'weekly'})
  const [cardSubtitleTimePeriodText, setCardSubtitleTimePeriodText] = useState("")

  useEffect(() => {
    const startDate = dayjs(calendarStartDate);
    const endDate = dayjs(calendarEndDate);
    const differenceInDays = endDate.diff(startDate, 'day');
    setGranularity({granularity: differenceInDays > 31 ? 'monthly' : 'weekly'})
    calculateCardSubtitleTimePeriodText()
  },[calendarStartDate, calendarEndDate])

  const calculateCardSubtitleTimePeriodText = () => {
    const matchedPreset = rangePresets.find((preset) => {
      return preset.value[0].format("YYYY-MM-DD") === calendarStartDate && preset.value[1].format("YYYY-MM-DD") === calendarEndDate
    })

    if (matchedPreset) {
      setCardSubtitleTimePeriodText(`${matchedPreset?.label}`)
    } else {
      setCardSubtitleTimePeriodText(`${dayjs(calendarStartDate).format("D MMM, YYYY")} - ${dayjs(calendarEndDate).format("D MMM, YYYY")}`)
    }
  }

  const onDateChange = (startDate, endDate) => {
    dispatch(SetAlertAnalyticsStartDate(startDate));
    dispatch(SetAlertAnalyticsEndDate(endDate));
    updateQueryParams(navigate, {'startDate': startDate, 'endDate': endDate});
  }

  return (
    <ChartSectionContainer>
      <FilterAndCalendarContainer>
        <FilterWithCalendar 
          filtersConfig={pagesContent.filterConfigurations?.alertAnalyticsPage?.filters}
          onSelectFilter={() => {}}
          onResetFilters={() => {}}
          disabledDate={analyticsDisabledDate}
          onDateChange={onDateChange}
          date={[calendarStartDate, calendarEndDate]}
          allowClear={false}
          page={'alertAnalytics'}
          rangePresets={[
            ...rangePresets, 
            {
              label: "Reset",
              value: [
                dayjs(defaultStartDate), 
                dayjs(defaultEndDate)
              ]
            } 
          ]}
        />
      </FilterAndCalendarContainer>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FleetAlertDistributionChart clickableHeader cardSubtitleTimePeriodText={cardSubtitleTimePeriodText} filters={selectedFilters} startDate={calendarStartDate} endDate={calendarEndDate} granularity={granularity}/>
        </Grid>
        <Grid item xs={6}>
          <FleetAlertStatus clickableHeader cardSubtitleTimePeriodText={cardSubtitleTimePeriodText} filters={selectedFilters} startDate={calendarStartDate} endDate={calendarEndDate} granularity={granularity}/>
        </Grid>
        <Grid item xs={6}>
          <FleetAvgResolutionTimeChart clickableHeader cardSubtitleTimePeriodText={cardSubtitleTimePeriodText} filters={selectedFilters} startDate={calendarStartDate} endDate={calendarEndDate} granularity={granularity}/>
        </Grid>
        <Grid item xs={6}>
          <FleetSeverityDistributionChart clickableHeader cardSubtitleTimePeriodText={cardSubtitleTimePeriodText} filters={selectedFilters} startDate={calendarStartDate} endDate={calendarEndDate} granularity={granularity}/>
        </Grid>
      </Grid>
    </ChartSectionContainer>
  )
}

export default ChartsSection
