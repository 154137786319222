import React, { useState, useEffect, useRef } from "react";
import Chart from "react-apexcharts";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { Close } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { DateFormatString } from "../../Helper/DatePicker/DateFormatters";
import "./charts.css";
import SlickCarouselPackMetrics from "../../Features/Carousel/SlickCarouselPackMetrics";
import moment from "moment";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";

const swapIndexes = (arr) => {
  if (!arr || arr.length < 2) {
    return arr;
  }

  const temp = arr[0];
  arr[0] = arr[1];
  arr[1] = temp;

  return arr;
};

const DigitalTwinApexMultiAxis = (props) => {
  const {
    batteryID,
    dataSeries,
    setChartRef,
    isDrawerOpen,
    annotationData,
    selectedMetrics,
    chartColorCoding,
    chargerData,
    isComparisonModeOn,
    optionData,
    startDate,
    selectedComparisonModeMetricData,
    isFetchingChartData,
    selectedAlertAnnotationID,
    selectedHierarchyLevel,
    isChartEmpty,
    viewportWidth,
    multiTenantConfig,
    ...other
  } = props;

  let dataArray = dataSeries?.dataArray || [];
  const [showAnnotationPopup, setShowAnnotationPopup] = useState(false);
  const [clickedAnnotationID, setClickedAnnotationID] = useState(null);
  const [zoomXAxis, setZoomXAxis] = useState(null);
  const chartRef = useRef(null);

  const isBatteryLevel = () => {
    return selectedHierarchyLevel === "battery"
  }

  const noChartDataTextLine1 =
    isBatteryLevel()
      ? "Chart does not exist for Battery System."
      : "No data available for selected date.";
  const noChartDataTextLine2 = "Try selecting Packs."

  const yAxisDecimalPlace =
    selectedComparisonModeMetricData?.metricConfig?.yAxisDecimalPlaces;

  let activeMatrixes = selectedMetrics.filter(
    (matrix) => matrix?.status == "active"
  );

  const getYAxisSettings = (matrix, settings = {}, range = {}) => {
    if (!matrix) {
      return {
        axisBorder: {
          show: true,
          color: "#87939D",
          width: 1.5,
          offsetX: -2,
        },
        labels: {
          formatter: function (value) {
            return value?.toFixed(0);
          },
        },
      };
    }

    return {
      seriesName: matrix.label || "NA",
      opposite: settings.opposite,
      axisBorder: {
        show: true,
        color: matrix.checked ? matrix.color : "#87939D",
        width: 1.5,
        offsetX: settings.borderOffsetX,
      },
      floating: settings.floating || false,
      labels: {
        offsetX: settings.labelOffsetX,
        maxWidth: 170,
        style: {
          colors: [matrix.checked ? "#87939D" : "transparent"],
          fontSize: "12px",
          fontWeight: "500",
          LineHeight: "16px",
          fontFamily: "Inter",
        },
        formatter: function (value) {
          return value?.toFixed(matrix.yAxisDecimalPlaces);
        },
      },
      title: {
        text: matrix.unit || "NA",
        offsetX: settings.titleOffsetX,
        rotate: 0,
        offsetY: optionData.dateType === "Weekly" && dataArray[0]['data'].length > 10 ? -170 : -185,
        style: {
          color: matrix.label
            ? matrix.checked
              ? matrix.color
              : "transparent"
            : "transparent",
          fontSize: "12px",
          fontWeight: "500",
          LineHeight: "16px",
          fontFamily: "Inter",
        },
      },

      min: range.yMin,
      max: range.yMax,

      axisTicks: {
        show: false,
        borderType: "solid",
        color: ThemeProperties.c_axis_labels_color,
        offsetX: 5.5,
      },
      tickAmount: 5,
    };
  };

  if (dataArray) {
    dataArray = dataArray.filter((item) => item && item.name != undefined);
  }

  // Due to the default rendering order of the chart axis being different from the checkboxes at the top,
  // we need to swap indices 0 and 1 to align the axis correctly with the checkboxes.
  dataArray = swapIndexes(dataArray);
  activeMatrixes = swapIndexes(activeMatrixes);

  let yAxisSettings = [getYAxisSettings()];

  let predefinedYAxisSettings = [
    {
      borderOffsetX: -9,
      floating: false,
      labelOffsetX: 0,
      titleOffsetX: 36,
      opposite: false,
    },
    {
      borderOffsetX: -6,
      floating: true,
      labelOffsetX: 28,
      titleOffsetX: 36,
      opposite: false,
    },

    {
      borderOffsetX: -8,
      floating: false,
      labelOffsetX: 0,
      titleOffsetX: -32,
      opposite: true,
    },

    {
      borderOffsetX: -7,
      floating: true,
      labelOffsetX: 29,
      titleOffsetX: -36,
      opposite: true,
    },
  ];

  const getRange = (item) => {
    let yMin = -10;
    let yMax = 50;

    if (item) {
      yMin = item.yMin;
      yMax = item.yMax;
    }
    return { yMin, yMax };
  };

  useEffect(() => {
    setChartRef(chartRef)
  }, [chartRef])

  activeMatrixes.forEach((activeMatrix, index) => {
    const range = getRange(dataArray[index]);
    yAxisSettings[index] = getYAxisSettings(
      activeMatrix,
      predefinedYAxisSettings[index],
      range
    );
  });

  const yAxisSettingsForComparisonMode = {
    axisTicks: {
      show: false,
      borderType: "solid",
      color: ThemeProperties.c_axis_labels_color,
    },
    tooltip: {
      enabled: false,
    },
    tickAmount: 5,
    axisBorder: {
      show: true,
      color: "#87939D",
      offsetX: -6,
      width: 1.5,
    },
    labels: {
      show: true,
      style: {
        colors: ["#87939D"],
        fontSize: "12px",
        fontWeight: "500",
        LineHeight: "16px",
        fontFamily: "Inter",
      },
      formatter: function (value) {
        return value?.toFixed(yAxisDecimalPlace);
      },
      offsetX: -3,
    },
    title: {
      text: selectedComparisonModeMetricData?.metricConfig?.unit || "-",
      offsetX: 36,
      rotate: 0,
      offsetY: optionData.dateType === "Weekly" && selectedComparisonModeMetricData?.series[0]?.data?.length > 10 ? -170: -185,
      style: {
        color: selectedComparisonModeMetricData?.metricConfig?.unit
          ? "#87939D"
          : "transparent",
        fontSize: "12px",
        fontWeight: "500",
        LineHeight: "16px",
        fontFamily: "Inter",
      },
    },
    min: selectedComparisonModeMetricData?.yMin,
    max: selectedComparisonModeMetricData?.yMax,
  };

  let xAxisType = "datetime";

  if (optionData.dateType && optionData.dateType != "Raw Data") {
    xAxisType = "category";
  }

  const xAxisSetting = {
    type: xAxisType,
    ...(xAxisType === "datetime" ? { tickPlacement: "on" } : {}),
    tooltip: {
      enabled: false,
    },
    title: {
      text: undefined,
    },
    labels: {
      show: true,
      datetimeUTC: false,
      rotate: 0,
      rotateAlways: false,
      hideOverlappingLabels: false,
      style: {
        colors: "#87939D",
        fontSize: "12px",
        fontFamily: "Roboto",
        fontWeight: "500",
        LineHeight: "16px",
      },
      offsetX: 0,
      offsetY: -2,
      format: optionData.dateType === "Raw Data" ? zoomXAxis?.xaxis?.labels?.format || "h TT" : undefined,
    },
    axisTicks: {
      show: true,
      height: 3,
      borderType: "solid",
      color: ThemeProperties.c_axis_labels_color,
    },
    axisBorder: {
      show: true,
      color: ThemeProperties.c_axis_labels_color,
    },
    min:
      optionData.dateType === "Raw Data"
        ? zoomXAxis?.xaxis?.min || new Date(DateFormatString(startDate)).getTime()
        : zoomXAxis?.xaxis?.min,
    max:
      optionData.dateType === "Raw Data"
        ? zoomXAxis?.xaxis?.max || new Date(DateFormatString(startDate)).getTime() + (isComparisonModeOn ? 87300000 : 86400000) // increasing max time for comparison mode since 12 am was not coming.
        : zoomXAxis?.xaxis?.max,
  };

  if (optionData.dateType && optionData.dateType != "Raw Data") {
    xAxisSetting["categories"] = optionData?.xAxisCategories || [];
  }

  const [modalData, setModalData] = useState([]);

  const [divStyle, setDivStyle] = useState({
    top: 0,
    left: 0,
    width: "285px",
    borderRadius: "10px",
  });

  const chartOptions = {
    chart: {
      animations: {
        enabled: false,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: false,
          speed: 350,
        },
      },
      events: {
        beforeZoom: function (chart, { xaxis }) {
          let newXAxis = {
            xaxis: {
              min: xaxis.min,
              max: xaxis.max
            },
          };

          if (optionData.dateType === "Raw Data") {
            const MIN_ZOOM_RANGE = 15 * 60 * 1000; // 15 minutes in milliseconds
            let min = xaxis.min;
            let max = xaxis.max;

            if (max - min >= 86400000) {
              newXAxis = {
                xaxis: {
                  min: new Date(startDate).getTime() - 19800000,
                  max:
                    new Date(startDate).getTime() +
                    86400000 -
                    19800000,
                  labels: {
                    format: "h TT",
                  },
                },
              };
              setZoomXAxis(newXAxis)
              return newXAxis
            }

            if (max - min < MIN_ZOOM_RANGE) {
              let newMax = min + MIN_ZOOM_RANGE;

              newXAxis = {
                xaxis: {
                  min: min,
                  max: newMax,
                  labels: {
                    format: "h:mm TT",
                  },
                },
              };
              setZoomXAxis(newXAxis)
              return newXAxis
            } else {
              newXAxis = {
                xaxis: {
                  min: min,
                  max: max,
                  labels: {
                    format: "h:mm TT",
                  },
                },
              };
              setZoomXAxis(newXAxis)
              return newXAxis
            }
          } else {
            setZoomXAxis(newXAxis)
          }
        },

        beforeResetZoom: function (chartContext, opts) {
          setZoomXAxis(null);
          if (optionData.dateType === "Raw Data") {
            return {
              xaxis: {
                min: new Date(startDate).getTime() - 19800000,
                max:
                  new Date(startDate).getTime() + 86400000 - 19800000,
                labels: {
                  show: true,
                  datetimeUTC: false,
                  rotate: 0,
                  rotateAlways: false,
                  style: {
                    colors: ThemeProperties.c_axis_labels_color,
                    fontSize: ThemeProperties.c_axis_labels,
                    fontFamily: "Roboto",
                    fontWeight: ThemeProperties.c_axis_labels_weight,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  format: "h TT",
                },
              },
            }
          }
        },
        click: function (event, chartContext, config) {
          if (event?.target?.parentNode?.id) {
            config.config.annotations.points.forEach((annot) => {
              // Comparing id of svg element
              // eslint-disable-next-line eqeqeq
              let validElementsForClick = [
                event?.target?.parentNode?.id,
              ]
              
              if (Array.from(event?.target?.classList)?.includes('apexcharts-point-annotation-marker')) {
                validElementsForClick.push(event.target?.parentElement?.querySelectorAll("svg")?.[0]?.id)
              }

              if (validElementsForClick.includes(annot?.customSVG?.id)) {
                setClickedAnnotationID(annot?.customSVG?.id);
                handleShowModal(
                  event.x,
                  event.y,
                  annot.x,
                  annot.y,
                  annot.label.borderColor,
                  annot.customSVG.text
                );
                setShowAnnotationPopup(true);
              }
            });
          } else {
            setShowAnnotationPopup(false);
            setClickedAnnotationID(null);
          }
        },
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: true,
          reset: true,
        },
        offsetX: -80,
        offsetY: -26,
      },
      offsetX: 0,
    },
    markers: {
      size: optionData.dateType === "Raw Data" ? 0 : 4,
    },
    annotations: {
      xaxis: annotationData.map((item) => {
        return {
          x: item.x,
          borderColor: "#E9EFF5",
          borderWidth: 3,
          fillColor: "#c2c2c2",
          strokeDashArray: 0,
          label: {
            orientation: "horizontal",
            style: {
              display: "none",
            },
          },
        };
      }),
      points: annotationData.map((item) => {
        return {
          x: item.x,
          marker: {
            size: 10,
            fillColor: item.id === clickedAnnotationID
              ? "#E1E3F0"
              : "#FFFFFF",
            strokeColor:
              item.id === selectedAlertAnnotationID
                ? decryptTheParams().selectedAlertAnnotationColor || 'red'
                : item.id === clickedAnnotationID
                  ? "#586CE9"
                  : "#87939D",
            cssClass: "",
            offsetY: -10
          },

          customSVG: {
            SVG: `
            <svg id="${item.id
              }" width='18' height='18' style="cursor: pointer;" viewBox='0 0 128 128' xmlns='http://www.w3.org/2000/svg' version='1.1' >
              <circle cx="64" cy="64" r="90" fill="transparent" stroke="transparent" stroke-width="2"/>
              <text text-anchor="middle" x="50%" y="50%" dy=".35em" font-family="sans-serif" font-size="80px" fill="${item.id === clickedAnnotationID &&
                item.id !== selectedAlertAnnotationID
                ? "#586CE9"
                : "#87939D"
              }">
                ${item.count}
              </text>
            </svg>
            `,
            offsetX: -9,
            offsetY: -19,
            pointValue: item.x,
            text: item.text,
            id: item.id,
          },
        };
      }),
    },
    grid: {
      show: true,
      borderColor: "#E9EFF5",
      strokeDashArray: 5,
      position: "back",
      xaxis: {
        lines: {
          show: optionData.dateType !== "Raw Data",
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    stroke: {
      width: 2,
      curve: 'straight',
    },
    yaxis: isComparisonModeOn ? yAxisSettingsForComparisonMode : yAxisSettings,
    xaxis: xAxisSetting,
    tooltip: {
      enabled: !isChartEmpty,
      followCursor: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
        const hoverIndexes = w.globals.seriesX.map(seriesX => {
          return seriesX.findIndex(xData => xData === hoverXaxis);
        });
        let hoverListHtml = '';
        let hoverItems = [];
        let tooltipItemContainerClass = 'tooltip-item-container'
        if (isComparisonModeOn) {
          tooltipItemContainerClass += " tooltip-item-container-justify-between"
        }
        
        hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
          let value = series[seriesEachIndex][hoverIndex];
          const seriesColor = w.config.series[seriesEachIndex].color;

          if (Number.isFinite(value)) {
            value = value.toFixed(2)
          }

          if (hoverIndex >= 0 && value !== null) {
            hoverItems.push(`
              <div class="${tooltipItemContainerClass}">
                <span class="dot" style="background-color: ${seriesColor};"></span>
                <span class="tooltip-item-text">
                  ${w.globals.seriesNames[seriesEachIndex]}: <strong>${value}</strong>
                </span>
              </div>
            `);
          }
        });

        if (!isComparisonModeOn) {
          hoverItems = swapIndexes(hoverItems)
        }

        const batteryDateTime = w.config.xaxis.type === "datetime" ? moment(new Date(hoverXaxis)).format("DD MMM'YY, hh:mm:ss A") : null
        const chargerDetails = chargerData?.data?.find(item => item.x === batteryDateTime)

        const data = w.config.xaxis.type === "datetime"
          ? batteryDateTime
          : w.globals.categoryLabels[hoverXaxis - 1];

        const useTwoColumns = hoverItems.length > 8;
        const columns = useTwoColumns ? 2 : 1;
        const rows = Math.ceil(hoverItems.length / columns);

        for (let row = 0; row < rows; row++) {
          for (let col = 0; col < columns; col++) {
            const itemIndex = row + col * rows;
            if (hoverItems[itemIndex]) {
              hoverListHtml += hoverItems[itemIndex];
            }
          }
        }
        const chargerDataConfig = multiTenantConfig?.chartTooltip?.chargerData
        const showChargerDataInTooltip = hoverListHtml.length && w.config.xaxis.type === "datetime" && chargerDataConfig?.show

        return (`
          <div>
            <span>
              <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px">
                ${data}
              </div>
            </span>
          </div>
          <div class="tooltip-container ${useTwoColumns ? 'tooltip-two-column' : ''}">
            ${hoverListHtml}
          </div>
          ${showChargerDataInTooltip
            ? `<div class="tooltip-charger-container">
                <div class="${tooltipItemContainerClass}">
                  <span class="tooltip-item-text">
                    ${chargerDataConfig?.chargerIDLabel || "Charger ID"}: <strong>${chargerDetails?.data?.chargerID || "-"}</strong>
                  </span>
                </div>
                <div class="${tooltipItemContainerClass}">
                    <span class="tooltip-item-text">
                      ${chargerDataConfig?.slotIDLabel || "Slot Position"}: <strong>${chargerDetails?.data?.slotID || "-"}</strong>
                    </span>
                </div>
              </div>` 
            : ''
          }
        `)
      }
    },
    legend: {
      show: false,
    },
  };

  const handleClosePopup = () => {
    setShowAnnotationPopup(false);
    setClickedAnnotationID(null);
  };

  const handleShowModal = (
    totalLeftPositionUptoAnnotation,
    y,
    xData,
    yData,
    borderColor,
    textData,
    alertType
  ) => {
    const obj = annotationData.find((item) => item.x === xData);
    const positionExcludingDrawerWidth =
      totalLeftPositionUptoAnnotation - (isDrawerOpen ? 262 : 104) - 50;
    const totalRightPositionUptoAnnotation =
      viewportWidth - totalLeftPositionUptoAnnotation;
    const widthOfPopup = 285;
    const offset = 20;

    const finalPosition =
      totalRightPositionUptoAnnotation < widthOfPopup / 2 + offset
        ? positionExcludingDrawerWidth - widthOfPopup
        : positionExcludingDrawerWidth < widthOfPopup / 2 + offset
          ? positionExcludingDrawerWidth
          : positionExcludingDrawerWidth - widthOfPopup / 2;

    setDivStyle({
      ...divStyle,
      top: 40,
      left: finalPosition,
    });
    setModalData(obj.data);
  };

  const series = isComparisonModeOn
    ? selectedComparisonModeMetricData?.series || []
    : dataArray;

  return (
    <Box
      style={{
        position: "relative",
      }}
    >
      {isChartEmpty && !isFetchingChartData && (
        <div
          style={{
            position: "absolute",
            left: viewportWidth < 1200 ? "32%" : "41.5%",
            top: "35%",
            zIndex: 2
          }}
        >
          <div
            style={{
              backgroundColor: "#ececec",
              padding: "10px",
              borderRadius: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="textSubtitle">
              {noChartDataTextLine1}
            </Typography>
            {isBatteryLevel() && <Typography variant="textSubtitle">
              {noChartDataTextLine2}
            </Typography>}
          </div>
        </div>
      )
      }
      <Chart
        ref={chartRef}
        id={
          optionData.dateType === "Raw Data" ? "hideAlternateLabel" : "donthide"
        }
        type="line"
        height="400px"
        width="100%"
        style={{
          paddingLeft: "15px",
          opacity: isFetchingChartData ? 0 : 1,
        }}
        options={{
          ...chartOptions,
        }}
        series={series}
      />
      {
        showAnnotationPopup && (
          <Box className="popUpContainer" style={divStyle}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                position: "absolute",
                top: "5px",
                right: "10px",
                zIndex: "2",
              }}
            >
              <Box></Box>
              <IconButton onClick={() => handleClosePopup()}>
                <Close className="closeIcon" />
              </IconButton>
            </Box>
            <Box className="popupContent">
              <SlickCarouselPackMetrics modalData={modalData} />
            </Box>
          </Box>
        )
      }
    </Box >
  );
};

export default DigitalTwinApexMultiAxis;
