import { SvgIcon } from "@mui/material"
import React from "react"

const SearchIconSvg = (props) => {
  const { height, width, viewBox, fill, stroke, ...other } = props
  return (
    <SvgIcon style={{ ...props.style }}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4734 13.5267L12.0001 11.0733C12.9601 9.87627 13.4251 8.35686 13.2993 6.82753C13.1735 5.2982 12.4665 3.87519 11.3237 2.85109C10.181 1.827 8.68926 1.27967 7.15534 1.32164C5.62142 1.36362 4.16187 1.99171 3.07682 3.07676C1.99177 4.16181 1.36368 5.62136 1.32171 7.15528C1.27973 8.6892 1.82706 10.1809 2.85115 11.3237C3.87525 12.4664 5.29826 13.1734 6.82759 13.2992C8.35692 13.425 9.87633 12.9601 11.0734 12L13.5267 14.4533C13.5887 14.5158 13.6624 14.5654 13.7437 14.5993C13.8249 14.6331 13.9121 14.6505 14.0001 14.6505C14.0881 14.6505 14.1752 14.6331 14.2564 14.5993C14.3377 14.5654 14.4114 14.5158 14.4734 14.4533C14.5936 14.329 14.6607 14.1629 14.6607 13.99C14.6607 13.8171 14.5936 13.651 14.4734 13.5267ZM7.33339 12C6.41041 12 5.50816 11.7263 4.74073 11.2135C3.9733 10.7007 3.37516 9.97191 3.02195 9.11919C2.66874 8.26646 2.57633 7.32815 2.75639 6.42291C2.93646 5.51766 3.38091 4.68614 4.03356 4.0335C4.6862 3.38085 5.51773 2.9364 6.42297 2.75633C7.32821 2.57627 8.26653 2.66868 9.11925 3.02189C9.97197 3.3751 10.7008 3.97324 11.2136 4.74067C11.7264 5.5081 12.0001 6.41035 12.0001 7.33333C12.0001 8.57101 11.5084 9.75799 10.6332 10.6332C9.75805 11.5083 8.57107 12 7.33339 12Z"
          fill={fill}
        />
      </svg>
    </SvgIcon>
  )
}

SearchIconSvg.defaultProps = {
  width: "16",
  height: "16",
  viewBox: "0 0 16 16",
  fill: "#444B51",
  stroke: ""
}

export default SearchIconSvg
