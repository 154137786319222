import React from "react"
import { SvgIcon } from "@mui/material"

const NavbarDiagnosticsIcon = (props) => {
  const { height, width, viewBox, fill, stroke, style, isSelected, ...other } = props
  return isSelected
    ? <SvgIcon
      style={style}
    >
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9995 2.89844H2.9043V18.0109H15.9995V2.89844Z" fill="#BBC5FF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2837 1.97656H6.62087V4.92535H12.2837V1.97656ZM9.39777 8.65482L8.88882 7.38316L8.15549 8.5295L6.95002 10.4123H2.9082V11.8867H7.70742L7.91765 11.5586L8.60002 10.4934L9.85999 13.6449L10.4553 15.1318L11.1469 13.6913L12.0133 11.8867H15.9999V10.4123H11.1384L10.943 10.82L10.5728 11.5911L9.39777 8.65482Z" fill="#4D44D8"/>
      </svg>
    </SvgIcon>
    : <SvgIcon
      style={style}
    >
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9995 2.89844H2.9043V18.0109H15.9995V2.89844Z" fill="#D3D3D3"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2837 1.97656H6.62087V4.92535H12.2837V1.97656ZM9.39777 8.65482L8.88882 7.38316L8.15549 8.5295L6.95002 10.4123H2.9082V11.8867H7.70742L7.91765 11.5586L8.60002 10.4934L9.85999 13.6449L10.4553 15.1318L11.1469 13.6913L12.0133 11.8867H15.9999V10.4123H11.1384L10.943 10.82L10.5728 11.5911L9.39777 8.65482Z" fill="#989898"/>
      </svg>

    </SvgIcon>
}

NavbarDiagnosticsIcon.defaultProps = {
  width: "19",
  height: "19",
  viewBox: "0 0 19 19",
  fill: "#D3D3D3",
  stroke: "",
  isSelected: false,
}

export default NavbarDiagnosticsIcon
