import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { PageSectionContainer } from "../../PagesContent/AlertAnalytics/Components/StyledComponent"
import { FlexBox } from "../../Components/CustomBox"
import { AnalyticsDrillDownPageTitleText, DrillDownPageContainer } from "../../PagesContent/AlertDrillDown/Components/StyledComponent"
import FilterSection from "../../Features/AnalyticsDrillDown/FilterSection"
import TableSection from "../../PagesContent/AlertDrillDown/TableSection"
import ChartSection from "../../PagesContent/AlertDrillDown/ChartSection"
import { updateQueryParams } from "../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { SetFilterValues } from "../../Actions/Filters";
import { analyticsDisabledDate } from "../../Helper/DatePicker/DateConverter";
import { getDatePickerDate, getPreviousMonthDate } from "../../Helper/DatePicker/DateConverter";
import { SetAlertAnalyticsStartDate, SetAlertAnalyticsEndDate } from "../../Actions/AlertAnalytics";
import { SetTablePageNumber } from "../../Actions/Table";

function AlertDrillDownMain({ pagesContent }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [granularity, setGranularity] = useState({granularity: 'daily'})
  const [chartFilterValue, setChartFilterValue] = useState({})
  const [isCardFilterMounted, setIsCardFilterMounted] = useState(false)
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);
  const defaultStartDate = getPreviousMonthDate(getDatePickerDate(undefined, -1))
  const defaultEndDate = getDatePickerDate(undefined, -1)
  const calendarStartDate = useSelector((state) => state.AlertAnalyticsStartDate.value) || defaultStartDate;
  const calendarEndDate = useSelector((state) => state.AlertAnalyticsEndDate.value) || defaultEndDate;

  const handleChartFilterValue = (filterMounted, value, label) => {
    if (label) {
      setChartFilterValue({[label]: [value]})
    }
    setIsCardFilterMounted(filterMounted)
  }

  const handleDateChange = (startDate, endDate) => {
    dispatch(SetTablePageNumber(1));
    dispatch(SetAlertAnalyticsStartDate(startDate));
    dispatch(SetAlertAnalyticsEndDate(endDate));
    updateQueryParams(navigate, {'startDate': startDate, 'endDate': endDate});
  }

  const handleResetFilters = () => {
    dispatch(SetFilterValues(defaultFilterValues))
    dispatch(SetAlertAnalyticsStartDate(defaultStartDate));
    dispatch(SetAlertAnalyticsEndDate(defaultEndDate));
    setGranularity({granularity: 'daily'})
    updateQueryParams(navigate, {
      filters: ""
    })
  }

  return (
    <FlexBox>
      <DrillDownPageContainer>
          <PageSectionContainer>
            <AnalyticsDrillDownPageTitleText>
              {pagesContent.pageHeader}
            </AnalyticsDrillDownPageTitleText>
          </PageSectionContainer>
          <ChartSection granularity={granularity} setGranularity={setGranularity} handleChartFilter={handleChartFilterValue}/>
          <TableSection pagesContent={pagesContent} chartFilterValue={chartFilterValue} isCardFilterMounted={isCardFilterMounted}/>
      </DrillDownPageContainer>
      <FilterSection 
        analyticsDisabledDate={analyticsDisabledDate}
        calendarStartDate={calendarStartDate}
        calendarEndDate={calendarEndDate}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate} 
        pagesContent={pagesContent}
        granularity={granularity}
        setGranularity={setGranularity}
        onDateChange={handleDateChange}
        onResetFilters={handleResetFilters} 
      />
    </FlexBox>
  )
}

export default AlertDrillDownMain
