import Chart from "react-apexcharts";
import CODE from "../../Static/Constants/StatusCodes";
import { DigitalTwinChartLoader } from "../../Components/CustomBox";
import { Box, Typography } from "@mui/material";
import PulseLoader from "react-spinners/MoonLoader";

const AnalyticsStackBarPlusLine = ({
  state,
  granularity,
  stackBarPlotDataSeriesList,
  linePlotDataSeries,
  linePlotYAxisSetting,
  barPlotYAxisSetting,
  hideAlternateLabels,
}) => {
  const isEmpty = ![CODE.LOADING, CODE.SUCCESS].includes(state);
  const options = {
    legend: {
      position: "top",
      fontSize: "12px",
      fontWeight: 400,
      fontFamily: "Roboto",
      labels: {
        colors: "#87939D",
      },
      markers: {
        offsetX: -1,
        offsetY: 0
      },  
      itemMargin: {
        horizontal: 4,
      },
    },
    chart: {
      type: "bar",
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
      },
    },
    markers: {
      size: 4,
    },
    stroke: {
      width: [...stackBarPlotDataSeriesList.map((barData) => 0), 3],
    },
    grid: {
      show: true,
      borderColor: "#E9EFF5",
      strokeDashArray: 0,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    states: {
      hover: {
          filter: {
              type: 'none',
          }
      },
      active: {
          allowMultipleDataPointsSelection: false,
          filter: {
              type: 'none',
          }
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
        color: "#E9EFF5",
        offsetX: 0,
        offsetY: 1,
      },
      axisTicks: {
        show: false,
        color: "#78909C",
        height: 4,
        offsetX: 0,
        offsetY: 0,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        rotate: 0,
        style: {
          colors: "#87939D",
          fontSize: "10px",
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          fontWeight: 500,
        },
        offsetY: -3,
      },
      title: {
        text: granularity,
        offsetX: 0,
        offsetY: -6,
        style: {
          color: "#87939D",
          fontSize: "12px",
          fontFamily: "Roboto",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-title",
        },
      },
    },
    yaxis: [
      ...stackBarPlotDataSeriesList.map((barData, index) =>
        index === 0
          ? barPlotYAxisSetting
          : { ...barPlotYAxisSetting, show: false }
      ),
      linePlotYAxisSetting,
    ],
    tooltip: {
      enabled: !isEmpty,
      style: {
        fontSize: '12px',
        fontFamily: "Roboto",
      },
      x: {
        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
          const yValue = series[seriesIndex][dataPointIndex];
          if (!Number.isFinite(yValue)) {
            return null;
          }
          return w?.config?.series[seriesIndex]['data'][dataPointIndex]['dateRangeString'];
        },
      },
      y: {
        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
          const yValue = series[seriesIndex][dataPointIndex];
          if (!Number.isFinite(yValue)) {
            return null;
          }
          return value;
        },
      },
    },
  };

  const ChartComponent = () => {
    let series = [...stackBarPlotDataSeriesList]
    if (linePlotDataSeries !== null) {
      series.push(linePlotDataSeries);
    }
    return (
      <Chart
        className={"analytics-chart " + (hideAlternateLabels ? "hide-alternate-labels" : "")}
        options={options}
        series={series}
        type="line"
        height="100%"
        width="100%"
      />
    );
  };

  const Loader = () => {
    return (
      <DigitalTwinChartLoader>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <PulseLoader color={"#B2AFC3"} size={30} speedMultiplier={1} />
        </Box>
        <Typography
          style={{
            color: "#B2AFC3",
            marginTop: "10px",
            fontSize: "14px",
          }}
        >
          Loading
        </Typography>
      </DigitalTwinChartLoader>
    );
  };

  const NoDataMessage = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: "40%",
          left: "40%",
          zIndex: 2,
        }}
      >
        <div
          style={{
            backgroundColor: "#ececec",
            padding: "10px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="textSubtitle">No Data Found</Typography>
        </div>
      </div>
    );
  };

  return (
    <>
      {state === CODE.LOADING ? <Loader /> : <ChartComponent />}
      {isEmpty && <NoDataMessage />}
    </>
  );
};

AnalyticsStackBarPlusLine.defaultProps = {
  hideAlternateLabels: false
}

export default AnalyticsStackBarPlusLine;
