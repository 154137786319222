import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"
import { CustomText, FlexBox, FlexCenterBox, FlexSpaceBetweenBox, FullWidthBox, OuterBox } from "../../../Components/CustomBox"

const PageContainer = styled(OuterBox)({
  paddingLeft: "55px",
})

const PageSectionContainer = styled(Box)({
  paddingLeft: "24px",
  paddingRight: "24px",
})

const PageHeaderContainer = styled(FlexSpaceBetweenBox)({
  alignItems: "center",
  marginTop: "4px",
  marginBottom: "16px"
})

const FilterAndCalendarContainer = styled(FlexBox)({
  marginBottom: "8px",
  alignItems: "center",
})

const VehicleConnectedLabel = styled(CustomText)({
  fontSize: "16px",
  fontWeight: "400",
  color: "#444B51"
})

const VehicleConnectedValue = styled(CustomText)({
  marginLeft: "4px",
  marginRight: "12px",
  fontSize: "16px",
  fontWeight: "500",
  color: "#444B51"
})

const CalendarBox = styled(Box)({
  marginRight: "16px",
})

const KPIContainer = styled(FlexBox)({
  alignItems: "center",
  flexWrap: "wrap",
  gap: "24px",
  marginBottom: "24px"
})

const KPICardContainer = styled(Box)({
  maxHeight: "110px",
  minWidth: "265px",
  padding: "22px 20px",
  borderRadius: "14px",
  background: "linear-gradient(180deg, #FFFFFF 0%, #F2F0FF 100%)",
  boxShadow: "4px 4px 4px 0px rgba(209, 209, 209, 0.25)",

  "@media (min-width: 1440px)": {
    flexGrow: 1
  },
})

const KPILoaderBox = styled(FlexCenterBox)({
  height: "62px",
  alignItems: "center",
})

const KPILabel = styled(CustomText)({
  fontWeight: "300",
  fontSize: "14px",
  color: "#1C1C1C",
  lineHeight: "12px"
})

const KPIValue = styled(CustomText)({
  marginTop: "16px",
  fontWeight: "500",
  fontSize: "18px",
  color: "#1C1C1C",
  lineHeight: "12px"
})

const KPIDate = styled(CustomText)({
  marginTop: "12px",
  fontWeight: "400",
  fontSize: "12px",
  color: "#828A92",
  lineHeight: "14px"
})

const ChartSectionContainer = styled(Box)({
  marginTop: "8px",
})

const HeatMapLegendText = styled(CustomText)({
  fontSize: "12px", 
  fontWeight: "400",
  color: "#87939D"
})

const HeatMapLegendGradientBox = styled(Box)(({ gradientStyle }) => {
  return ({
    marginLeft: "8px", 
    marginRight: "8px", 
    width: "128px", 
    height: "16px",
    background: gradientStyle
  })
})

const HeatMapLegendContainer = styled(FullWidthBox)({
  marginTop: "5px",
  display: "flex", 
  justifyContent: "center",
  alignItems: "center",
})

export { 
  KPIDate,
  KPIValue,
  KPILabel,
  CalendarBox,
  PageContainer,
  VehicleConnectedLabel,
  VehicleConnectedValue,
  PageSectionContainer,
  HeatMapLegendText,
  HeatMapLegendContainer,
  HeatMapLegendGradientBox,
  PageHeaderContainer,
  FilterAndCalendarContainer,
  KPIContainer,
  KPICardContainer,
  KPILoaderBox,
  ChartSectionContainer
}
