import { SvgIcon } from "@mui/material"
import React from "react"

const NavbarMapIcon = (props) => {
  const { height, width, viewBox, fill, stroke, style, isSelected, ...other } = props
  return isSelected 
    ? <SvgIcon
      style={style}
    >
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7542 12.8893H3.24506L1 18.5282H18L15.7549 12.8877L15.7542 12.8893Z" fill="#4D44D8"/>
        <path d="M9.665 15.3682C9.665 15.3682 15.2306 10.978 15.2306 7.0633C15.2314 4.02643 13.1437 1.46582 9.665 1.46582C6.18554 1.46582 4.09863 4.02643 4.09863 7.0633C4.09863 10.978 9.665 15.3682 9.665 15.3682Z" fill="#BBC5FF"/>
        <path d="M8.31543 5.21973H11.0157V8.05944H8.31543V5.21973Z" fill="#4D44D8"/>
      </svg>
    </SvgIcon >
    : <SvgIcon
      style={style}
    >
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7542 12.8893H3.24506L1 18.5282H18L15.7549 12.8877L15.7542 12.8893Z" fill="#989898"/>
        <path d="M9.665 15.3682C9.665 15.3682 15.2306 10.978 15.2306 7.0633C15.2314 4.02643 13.1437 1.46582 9.665 1.46582C6.18554 1.46582 4.09863 4.02643 4.09863 7.0633C4.09863 10.978 9.665 15.3682 9.665 15.3682Z" fill="#D3D3D3"/>
        <path d="M8.31543 5.21973H11.0157V8.05944H8.31543V5.21973Z" fill="#989898"/>
      </svg>
    </SvgIcon >
}

NavbarMapIcon.defaultProps = {
  width: "19",
  height: "19",
  viewBox: "0 0 19 19",
  fill: "#797979",
  stroke: "",
  isSelected: false,
}

export default NavbarMapIcon
